import React from 'react'
import "./rowGall.css"
import { Image } from 'antd'
import gall1 from "../../Assets/Kitchen/kitchen1.png"
import gall2 from "../../Assets/Trunkey/trunkey3.png"
import gall3 from "../../Assets/Civil/civil5.png"
import gall4 from "../../Assets/interior/CAM 3.jpg"
import gall5 from "../../Assets/interior/NAIK'S INTERIOR  PPT (1)-06.png"
import gall6 from "../../Assets/interior/NAIK'S INTERIOR  PPT (1)-18.png"
function RowGallery() {
  return (
    <>
      <section>
        <div className="container">
            <div className="parents">
                
                <h2 className="Smalltitle"><span className='interial-con'>Gallery</span> </h2>
               <h5 className='view-gall'> <a href='/workpage'>View more</a></h5>
                <div className="row">
                    <div className="col-lg-4 col-lg-4 d-flex justify-content-center">
                        <div className="img-gallery">
                        <Image src={gall1} alt="" />
                        </div>
            
                    </div>
                    <div className="col-lg-4 d-flex justify-content-center">
                        <div className="img-gallery">
                        <Image src={gall2} alt="" />
                        </div>
            
                    </div>
                    <div className="col-lg-4 d-flex justify-content-center">
                        <div className="img-gallery">
                        <Image src={gall3} alt="" />
                        </div>
            
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 d-flex justify-content-center">
                        <div className="img-gallery">
                        <Image src={gall4} alt="" />
                        </div>
            
                    </div>
                    <div className="col-lg-4 d-flex justify-content-center">
                        <div className="img-gallery">
                        <Image src={gall5} alt="" />
                        </div>
            
                    </div>
                    <div className="col-lg-4 d-flex justify-content-center">
                        <div className="img-gallery">
                        <Image src={gall6} alt="" />
                        </div>
            
                    </div>
                </div>
               
            
     
            </div>
        </div>
      </section>
    </>
  )
}

export default RowGallery
