import React from 'react'
import WorkP from '../Components/WorkP/WorkP'
import Footer from '../Components/Footer/Footer'

function WorkPage() {
  return (
    <>
      <WorkP/>
      <Footer/>
    </>
  )
}

export default WorkPage
