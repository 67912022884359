import "./service.css"
import 'swiper/css';
import trunkey1 from "../../Assets/Trunkey/trunkey4.png"
import interior from "../../Assets/interior/NAIK'S INTERIOR  PPT (1)-06.png"
import civil1 from "../../Assets/Civil/civil3.png"
import kitchen from "../../Assets/Kitchen/kitchen1.png"
import furniture from "../../Assets/Furniture/fur5.png"
import { useState } from 'react';
import React from 'react';
import { Button, Modal } from 'antd';

import {Swiper, SwiperSlide } from 'swiper/react'

const stoppp = () =>{
  
}

function Service() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
 
  return (
    <>
      <section>
        <div className="container">
        <div data-v-05e57800="" data-id="oneStepShop" className="py-8 lg:py-16 about-mar">
  <div
    data-v-8ff92856=""
    data-v-05e57800=""
    className="wrapper-class-secondary"
  >
     <h2 className="md:text-center">
      Transform Your Space with Expert{' '}
      <span style={{ display: 'inline-block' }}>
      <span className='interial-con'>Interior Concepts</span>
      </span>
    </h2>
    <p
      data-v-8ff92856=""
      className="md:text-center md:text-xl md:leading-7 md:w-3/5 m-auto mt-3"
    >
      Transform your space with expert advice from the Top Interior Designers in Mira Road. Maximise functionality and aesthetics to create a personalised, stylish environment that effortlessly reflects your unique taste and meets your needs.
    </p>{" "}
    <div
      data-v-c1bf5930=""
      data-v-8ff92856=""
      className="mt-4 md:mt-4 md:pl-0 items-list carousel"
    >
      <div
        data-v-175fc4f6=""
        data-v-c1bf5930=""
        className="list-view-carousel slides-width"
      >
        <div
          data-v-d2dc48e8=""
          data-v-175fc4f6=""
          className="relative carousel-wrapper scrollbar-white"
        >
          <div
            data-v-4cf180d8=""
            data-v-d2dc48e8=""
            className="relative swiper-container-wrapper show-next"
          >
            <div
              data-v-4cf180d8=""
              instancename={1716629381277}
              className="swiper full-viewport-swiper swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-free-mode"
            >
              <div
                data-v-4cf180d8=""
                style={{ transform: "translate3d(0px, 0px, 0px)" }}
              >
                
                <Swiper
                 slidesPerView={1}
                 spaceBetween={10}
                 pagination={{
                   clickable: true,
                 }}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                      },
                      1024: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                      },
                    }}
                    spaceBetween={10}
                    // slidesPerView={3}
                    onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      className="mySwiper"

    >
      <SwiperSlide>
      <div
                  data-v-175fc4f6=""
                  data-v-4cf180d8=""
                  className="swiper-slide"
                  style={{   }}
                >
                  <div
                    data-v-175fc4f6=""
                    data-v-4cf180d8=""
                    className="slot-wrapper h-full"
                  >
                    <div
                      data-v-8ff92856=""
                      data-v-4cf180d8=""
                      className="rounded-2xl overflow-hidden card"
                      style={{ height: 389 }}
                    >
                     
                        <div
                          data-v-8ff92856=""
                          className="flex flex-col card-item h-full"
                        >
                          <div data-v-8ff92856="" className="image-container">
                            <img
                              data-v-8ff92856=""
                              loading="lazy"
                              src={trunkey1}
                              width={420}
                              height={234}
                              srcSet={trunkey1}
                              sizes="320px"
                              fetchpriority="auto"
                              className="flex-grow loaded"
                            />
                          </div>{" "}
                          <div
                            data-v-8ff92856=""
                            className="flex justify-center items-center flex-col padd flex-grow"
                          >
                            <div data-v-8ff92856="" className="flex-grow">
                              <h4
                                data-v-8ff92856=""
                                className="desktop-card-title text-center"
                              >
                               1 BHK
                              </h4>{" "}
                              <p
                                data-v-8ff92856=""
                                className="card-subtitle text-center mt-2"
                              >
                                Tailored interiors that redefine elegance
                              </p>
                            </div>{" "}
                            <div
                              data-v-8ff92856=""
                              className=" rounded-full h-8 w-8 flex items-center justify-center icon"
                            >
                              <span
                                className="material-icons"
                                style={{ fontSize: 24 }}
                              >
                                <Button onClick={showModal}>Get Quote</Button>

                              </span>
                            </div>
                          </div>
                        </div>
                      
                    </div>
                  </div>
                </div>
      </SwiperSlide>
      
      <SwiperSlide>
      <div
                  data-v-175fc4f6=""
                  data-v-4cf180d8=""
                  className="swiper-slide"
                  style={{   }}
                >
                  <div
                    data-v-175fc4f6=""
                    data-v-4cf180d8=""
                    className="slot-wrapper h-full"
                  >
                    <div
                      data-v-8ff92856=""
                      data-v-4cf180d8=""
                      className="rounded-2xl overflow-hidden card"
                      style={{ height: 389 }}
                    >
                      
                        <div
                          data-v-8ff92856=""
                          className="flex flex-col card-item h-full"
                        >
                          <div data-v-8ff92856="" className="image-container">
                            <img
                              data-v-8ff92856=""
                              loading="lazy"
                              src={interior}
                              width={420}
                              height={234}
                              srcSet={interior}
                              sizes="320px"
                              fetchpriority="auto"
                              className="flex-grow loaded"
                            />
                          </div>{" "}
                          <div
                            data-v-8ff92856=""
                            className="flex justify-center items-center flex-col padd flex-grow"
                          >
                            <div data-v-8ff92856="" className="flex-grow">
                              <h4
                                data-v-8ff92856=""
                                className="desktop-card-title text-center"
                              >
                              2 BHK
                              </h4>{" "}
                              <p
                                data-v-8ff92856=""
                                className="card-subtitle text-center mt-2"
                              >
                                Tailored interiors that redefine elegance
                              </p>
                            </div>{" "}
                            <div
                              data-v-8ff92856=""
                              className=" rounded-full h-8 w-8 flex items-center justify-center icon"
                            >
                              <span
                                className="material-icons"
                                style={{ fontSize: 24 }}
                              >
                               <Button onClick={showModal}>Get Quote</Button>

                              </span>
                            </div>
                          </div>
                        </div>
                      
                    </div>
                  </div>
                </div>
      </SwiperSlide>

      <SwiperSlide>
      <div
                  data-v-175fc4f6=""
                  data-v-4cf180d8=""
                  className="swiper-slide"
                  style={{   }}
                >
                  <div
                    data-v-175fc4f6=""
                    data-v-4cf180d8=""
                    className="slot-wrapper h-full"
                  >
                    <div
                      data-v-8ff92856=""
                      data-v-4cf180d8=""
                      className="rounded-2xl overflow-hidden card"
                      style={{ height: 389 }}
                    >
                     
                        <div
                          data-v-8ff92856=""
                          className="flex flex-col card-item h-full"
                        >
                          <div data-v-8ff92856="" className="image-container">
                            <img
                              data-v-8ff92856=""
                              loading="lazy"
                              src={civil1}
                              width={420}
                              height={234}
                              srcSet={civil1}
                              sizes="320px"
                              fetchpriority="auto"
                              className="flex-grow loaded"
                            />
                          </div>{" "}
                          <div
                            data-v-8ff92856=""
                            className="flex justify-center items-center flex-col padd flex-grow"
                          >
                            <div data-v-8ff92856="" className="flex-grow">
                              <h4
                                data-v-8ff92856=""
                                className="desktop-card-title text-center"
                              >
                               3 BHK
                              </h4>{" "}
                              <p
                                data-v-8ff92856=""
                                className="card-subtitle text-center mt-2"
                              >
                                Tailored interiors that redefine elegance
                              </p>
                            </div>{" "}
                            <div
                              data-v-8ff92856=""
                              className=" rounded-full h-8 w-8 flex items-center justify-center icon"
                            >
                              <span
                                className="material-icons"
                                style={{ fontSize: 24 }}
                              >
                                <Button onClick={showModal}>Get Quote</Button>

                              </span>
                            </div>
                          </div>
                        </div>
                    
                    </div>
                  </div>
                </div>
      </SwiperSlide>

      <SwiperSlide>
      <div
                  data-v-175fc4f6=""
                  data-v-4cf180d8=""
                  className="swiper-slide"
                  style={{   }}
                >
                  <div
                    data-v-175fc4f6=""
                    data-v-4cf180d8=""
                    className="slot-wrapper h-full"
                  >
                    <div
                      data-v-8ff92856=""
                      data-v-4cf180d8=""
                      className="rounded-2xl overflow-hidden card"
                      style={{ height: 389 }}
                    >
                      
                        <div
                          data-v-8ff92856=""
                          className="flex flex-col card-item h-full"
                        >
                          <div data-v-8ff92856="" className="image-container">
                            <img
                              data-v-8ff92856=""
                              loading="lazy"
                              src={kitchen}
                              width={420}
                              height={334}
                              srcSet={kitchen}
                              sizes="320px"
                              fetchpriority="auto"
                              className="flex-grow loaded"
                            />
                          </div>{" "}
                          <div
                            data-v-8ff92856=""
                            className="flex justify-center items-center flex-col padd flex-grow"
                          >
                            <div data-v-8ff92856="" className="flex-grow">
                              <h4
                                data-v-8ff92856=""
                                className="desktop-card-title text-center"
                              >
                               4 BHK
                              </h4>{" "}
                              <p
                                data-v-8ff92856=""
                                className="card-subtitle text-center mt-2"
                              >
                                Tailored interiors that redefine elegance
                              </p>
                            </div>{" "}
                            <div
                              data-v-8ff92856=""
                              className=" rounded-full h-8 w-8 flex items-center justify-center icon"
                            >
                              <span
                                className="material-icons"
                                style={{ fontSize: 24 }}
                              >
                               <Button onClick={showModal}>Get Quote</Button>

                              </span>
                            </div>
                          </div>
                        </div>
                      
                    </div>
                  </div>
                </div>
      </SwiperSlide>
      <SwiperSlide>
      <div
                  data-v-175fc4f6=""
                  data-v-4cf180d8=""
                  className="swiper-slide"
                  style={{   }}
                >
                  <div
                    data-v-175fc4f6=""
                    data-v-4cf180d8=""
                    className="slot-wrapper h-full"
                  >
                    <div
                      data-v-8ff92856=""
                      data-v-4cf180d8=""
                      className="rounded-2xl overflow-hidden card"
                      style={{ height: 389 }}
                    >
                     
                        <div
                          data-v-8ff92856=""
                          className="flex flex-col card-item h-full"
                        >
                          <div data-v-8ff92856="" className="image-container">
                            <img
                              data-v-8ff92856=""
                              loading="lazy"
                              src={furniture}
                              width={420}
                              height={234}
                              srcSet={furniture}
                              sizes="320px"
                              fetchpriority="auto"
                              className="flex-grow loaded"
                            />
                          </div>{" "}
                          <div
                            data-v-8ff92856=""
                            className="flex justify-center items-center flex-col padd flex-grow"
                          >
                            <div data-v-8ff92856="" className="flex-grow">
                              <h4
                                data-v-8ff92856=""
                                className="desktop-card-title text-center"
                              >
                              5 BHK 
                              </h4>{" "}
                              <p
                                data-v-8ff92856=""
                                className="card-subtitle text-center mt-2"
                              >
                                Tailored interiors that redefine elegance
                              </p>
                            </div>{" "}
                            <div
                              data-v-8ff92856=""
                              className=" rounded-full h-8 w-8 flex items-center justify-center icon"
                            >
                              <span
                                className="material-icons"
                                style={{ fontSize: 24 }}
                                
                              >
                                
                                <Button onClick={showModal}>Get Quote</Button>

                              </span>
                            </div>
                          </div>
                        </div>
                      
                    </div>
                  </div>
                </div>
      </SwiperSlide>
    </Swiper>   
              </div>{" "}
              {/**/}{" "}
              <div
                data-v-4cf180d8=""
                slot="scrollbar"
                id="swiper-scrollbar-1716629381277"
                className="swiper-scrollbar"
              >
                <div
                  className="swiper-scrollbar-drag"
                  style={{
                    transform: "translate3d(0px, 0px, 0px)",
                    width: "79.691px"
                  }}
                />
              </div>
              <span
                className="swiper-notification"
                aria-live="assertive"
                aria-atomic="true"
              />
            </div>
          </div>{" "}
          {/* <div data-v-d2dc48e8="" className="swiper-prev arrow arrow-pos-left">
            <span
              data-v-d2dc48e8=""
              className="icon-arrow-left-icon arrow-icon flex absolute left-50 topadd0"
            />
          </div>{" "}
          <div data-v-d2dc48e8="" className="swiper-next arrow arrow-pos-right">
            <span
              data-v-d2dc48e8=""
              className="icon-arrow-right-icon arrow-icon flex absolute left-50 topadd0"
            />
          </div> */}
        </div>
      </div>
    </div>
  </div>
</div>
        </div>
      </section>


<section>

<Modal onClick={stoppp} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

    
<section
    className="bfcForm_registerLoginContainer__lrgC4 bfcForm_bfcContainer__3JofO bfcForm_cityLandingBfc__2QBnl undefined fadeIn undefined"
    id="bfcContainer"
  >
    <style
      dangerouslySetInnerHTML={{
        __html:
          "\n                        #bfcContainer .allow-dropdown.intl-tel-input {display: block;}\n                        #bfcContainer .intl-tel-input .country-list { white-space: inherit; width: 100%; z-index: 3;}\n                        #bfcContainer .under-border .intl-tel-input.allow-dropdown .selected-flag { display: inline-flex; align-items: center; top: 0px; border-bottom: 0px solid rgba(71, 74, 80, 0.2);}\n                        #bfcContainer .boxed-border .intl-tel-input.allow-dropdown .selected-flag { justify-content: center; }\n                        #bfcContainer .intl-tel-input .country-list .country {padding: 10px 5px; line-height: 16px; font-size: 13px; font-weight: 500;}\n                        #bfcContainer .intl-tel-input.allow-dropdown .flag-container:hover .selected-flag { background: unset; }\n                        #bfcContainer .iti-flag { min-height: 14px; max-height: 14px; background-color: unset; box-shadow: unset;}\n                        @media (max-width: 768px) {#bfcContainer .intl-tel-input.allow-dropdown .selected-flag { top: 1px;}; .divider { height: unset; background: unset;}} \n                        \n                    "
      }}
    />
    <div
      className="bfcForm_registerComponent__2qSic leadgenform undefined"
      id="bfcReg"
    >
      <div id="bfcImage" />
      <h4 className="bfcForm_bfcTitle1__3cH7a" id="bfcTitle1">
        Get a Free Quote
      </h4>
      <div className="bfcForm_frm-grp__1ZfJi undefined">
        <input
          placeholder="Enter your name"
          id="bfc-username"
          type="text"
          name="name"
          maxLength={100}
          className="bfcForm_form-input__2UiIr bfcForm_errorInput__1xW7C"
          defaultValue=""
        />
        <svg viewBox="0 0 24 24" style={{ display: "none" }}>
          <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
        </svg>
        <label className="bfcForm_error-lab__26t8c" id="name__error">
          Please enter your name.
        </label>
      </div>
      <div className="bfcForm_frm-grp__1ZfJi undefined">
        <input
          placeholder="Enter your email"
          id="bfc-useremail"
          type="email"
          name="email"
          className="bfcForm_form-input__2UiIr"
          defaultValue=""
        />
        <svg viewBox="0 0 24 24" style={{ display: "none" }}>
          <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
        </svg>
        <div id="emailError" className="bfcForm_errMsg__2FOFZ">
          User Exists. Please{" "}
          <a className="bfcForm_errLink__2gYHC" href="javaScript:void(0)">
            login
          </a>{" "}
          or use a different email.
        </div>
      </div>
      <div className="bfcForm_frm-grp__1ZfJi undefined">
        <style
          dangerouslySetInnerHTML={{
            __html:
              ".intl-tel-input.allow-dropdown .selected-flag{justify-content:flex-start;}"
          }}
        />
        <div className="bfcForm_intlTelInput__237bu bfcForm_under-border__2cPq0 heloo-g">
          <input
            placeholder="Enter your mobile number"
            id="bfc-userphone"
            type="tel"
            name="phone"
            maxLength={10}
            className="bfcForm_form-input__2UiIr bfcForm_add-country-box__38XVl"
            defaultValue=""
          />
        </div>
        <div className="bfcForm_optwhatsappcont__2WB6-">
          <div className="toggleSwitch_wrapper__2VcEv undefined">
            <div className="toggleSwitch_grouped__3VVOI">
              <div className="toggleSwitch_outer__13Iz- undefined undefined">
                {/* <div className="toggleSwitch_text__sCw9q">
                  You can reach me on WhatsApp
                </div> */}
                <div className="toggleSwitch_toggleWrapper__1Ovne undefined">
                  <div className="toggleSwitch_toggle_switch__1LQOP">
                    <label className="toggleSwitch_label__3SvWi">
                      
                      <span className="toggleSwitch_inner__3-QeU" />
                      <span className="toggleSwitch_switch__2SAnW" />
                    </label>
                  </div>
                </div>
              </div>
              {/* <div className="toggleSwitch_subText__14aPg undefined">
                Uncheck to opt-out of upcoming meetings and offer alerts
              </div> */}
            </div>
          </div>
        </div>
        <svg className="bfcForm_mobileTick__Jjt5g" viewBox="0 0 24 24">
          <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
        </svg>
        <div id="mobileError" className="bfcForm_errMsg__2FOFZ">
          This phone number is associated with the email id . Please{" "}
          <a className="bfcForm_errLink__2gYHC" href="javaScript:void(0)">
            login
          </a>{" "}
          using that email id. If this is not your email id,{" "}
          <a href="javaScript:void(0)" className="bfcForm_errLink__2gYHC">
            click here to report the error
          </a>
          .
        </div>
      </div>
      <div className="bfcForm_frm-grp__1ZfJi undefined">
        <input
          placeholder="Enter Your Message"
          type="text"
          id="pincode"
          name="pincode"
          className="bfcForm_form-input__2UiIr"
          defaultValue=""
        />
        <svg viewBox="0 0 24 24" style={{ display: "none" }}>
          <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
        </svg>
      </div>
      <div id="alreadyUser" style={{ display: "none" }}>
        <p>
          Already User? <span>Sign in</span>
        </p>
      </div>
      <div className="bfcForm_frm-grp__1ZfJi undefined bfcForm_btn-block__q815q">
        <button id="bfc" className="bfcForm_bfc__IRpmv" type="submit">
          {/* <span>sub</span> */}
          <span className="bfcForm_largeText__2hTo1">Submit</span>
          {/* <span>t</span> */}
          <span className="bfcForm_label__NW7wY" />
        </button>
        <p className="bfcForm_term-condition-line__qpig9 undefined">
          By submitting this form, you agree to the{" "}
          <a
            className="bfcForm_term-condition-link__3u0dN"
            href="https://www.homelane.com/privacypolicy"
          >
            privacy policy
          </a>{" "}
          and{" "}
          <a
            className="bfcForm_term-condition-link__3u0dN"
            href="https://www.homelane.com/termsofuse"
          >
            {" "}
            terms of use{" "}
          </a>
        </p>
      </div>
    </div>
    <div
      className="bfcForm_registerComponent__2qSic leadgenform bfcForm_BfcFormWrapper__168cm undefined"
      id="proceed-anyway"
      style={{ display: "none" }}
    >
      <p className="bfcForm_closeProceedAnyway__JVlr8">x</p>
      <div className="bfcForm_proceedAnywayWrapper__3xDgu">
        <h2>We're not there yet!</h2>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={240}
          height={130}
          viewBox="0 0 240 130"
        >
          <g fill="none" fillRule="evenodd" stroke="none" strokeWidth={1}>
            <g fillRule="nonzero" transform="translate(-1037 -231)">
              <g transform="translate(1025 154)">
                <g transform="translate(12 77)">
                  <g>
                    <path
                      fill="#3F3D56"
                      d="M51.834 128.774h-.54c0-14.015-11.362-25.377-25.377-25.377C11.902 103.397.54 114.76.54 128.774H0c0-14.314 11.603-25.917 25.917-25.917 14.313 0 25.917 11.603 25.917 25.917zM239.73 128.774h-.54c0-14.015-11.361-25.377-25.377-25.377-14.015 0-25.377 11.362-25.377 25.377h-.54c0-14.314 11.604-25.917 25.917-25.917 14.314 0 25.917 11.603 25.917 25.917zM176.288 66.952h-.54c0-25.604-20.83-46.435-46.434-46.435S82.88 41.347 82.88 66.952h-.54c0-25.902 21.072-46.974 46.974-46.974 25.902 0 46.974 21.072 46.974 46.974z"
                    />
                    <circle cx="39.28" cy="10.664" r="10.664" fill="#E71C24" />
                    <path
                      fill="#3F3D56"
                      d="M41.5453768 84.0631721L59.8655568 62.2453093 95.8841845 62.2453093 117.895613 83.6849494 117.450979 83.9708436 117.609719 83.9708436 117.609719 128.565219 41.5704837 128.565219 41.5704837 83.9708436z"
                    />
                    <path
                      fill="#3F3D56"
                      d="M52.568 60.202H56.721V74.577H52.568z"
                      transform="rotate(-180 54.644 67.39)"
                    />
                    <path
                      fill="#F99D1C"
                      d="M59.5799325 61.9594151L82.1628796 88.4358155 82.1628796 128.565219 41.5704837 128.565219 41.5704837 83.3990551z"
                    />
                    <path
                      fill="#3F3D56"
                      d="M68.654 93.69H76.94399999999999V102.166H68.654z"
                    />
                    <path
                      fill="#3F3D56"
                      d="M68.654 105.224H76.94399999999999V113.7H68.654z"
                    />
                    <path
                      fill="#FFF"
                      d="M68.654 93.69H76.94399999999999V102.166H68.654z"
                    />
                    <path
                      fill="#FFF"
                      d="M68.654 105.224H76.94399999999999V113.7H68.654z"
                    />
                    <path
                      fill="#3F3D56"
                      d="M57.189 93.69H65.479V102.166H57.189z"
                    />
                    <path
                      fill="#3F3D56"
                      d="M57.189 105.224H65.479V113.7H57.189z"
                    />
                    <path
                      fill="#FFF"
                      d="M57.189 93.69H65.479V102.166H57.189z"
                    />
                    <path
                      fill="#FFF"
                      d="M57.189 105.224H65.479V113.7H57.189z"
                    />
                    <path
                      fill="#3F3D56"
                      d="M45.724 93.69H54.013999999999996V102.166H45.724z"
                    />
                    <path
                      fill="#3F3D56"
                      d="M45.724 105.224H54.013999999999996V113.7H45.724z"
                    />
                    <path
                      fill="#FFF"
                      d="M45.724 93.69H54.013999999999996V102.166H45.724z"
                    />
                    <path
                      fill="#FFF"
                      d="M45.724 105.224H54.013999999999996V113.7H45.724z"
                    />
                    <path
                      fill="#F9A826"
                      d="M130.934 10.799H134.714V20.247999999999998H130.934z"
                    />
                    <path
                      fill="#F99D1C"
                      d="M102.317 19.438H134.44299999999998V128.774H102.317z"
                    />
                    <path
                      fill="#3F3D56"
                      d="M134.443 19.438H147.401V128.774H134.443z"
                    />
                    <path
                      fill="#FFF"
                      d="M108.121 28.886H112.97999999999999V33.745H108.121z"
                    />
                    <path
                      fill="#FFF"
                      d="M115.951 28.886H120.80999999999999V33.745H115.951z"
                    />
                    <path
                      fill="#FFF"
                      d="M123.78 28.886H128.639V33.745H123.78z"
                    />
                    <path
                      fill="#FFF"
                      d="M108.256 39.955H113.115V44.814H108.256z"
                    />
                    <path
                      fill="#FFF"
                      d="M116.085 39.955H120.94399999999999V44.814H116.085z"
                    />
                    <path
                      fill="#FFF"
                      d="M123.915 39.955H128.774V44.814H123.915z"
                    />
                    <path
                      fill="#FFF"
                      d="M108.391 51.024H113.25V55.883H108.391z"
                    />
                    <path
                      fill="#FFF"
                      d="M116.22 51.024H121.079V55.883H116.22z"
                    />
                    <path
                      fill="#FFF"
                      d="M124.049 51.024H128.90800000000002V55.883H124.049z"
                    />
                    <path
                      fill="#FFF"
                      d="M108.526 62.092H113.38499999999999V66.951H108.526z"
                    />
                    <path
                      fill="#FFF"
                      d="M116.355 62.092H121.214V66.951H116.355z"
                    />
                    <path
                      fill="#FFF"
                      d="M124.184 62.092H129.043V66.951H124.184z"
                    />
                    <path
                      fill="#FFF"
                      d="M108.661 73.161H113.52V78.02H108.661z"
                    />
                    <path
                      fill="#FFF"
                      d="M116.49 73.161H121.34899999999999V78.02H116.49z"
                    />
                    <path
                      fill="#FFF"
                      d="M124.319 73.161H129.178V78.02H124.319z"
                    />
                    <path
                      fill="#FFF"
                      d="M108.796 84.229H113.655V89.088H108.796z"
                    />
                    <path
                      fill="#FFF"
                      d="M116.625 84.229H121.484V89.088H116.625z"
                    />
                    <path
                      fill="#FFF"
                      d="M124.454 84.229H129.313V89.088H124.454z"
                    />
                    <path
                      fill="#FFF"
                      d="M108.931 95.298H113.78999999999999V100.157H108.931z"
                    />
                    <path
                      fill="#FFF"
                      d="M116.76 95.298H121.619V100.157H116.76z"
                    />
                    <path
                      fill="#FFF"
                      d="M124.589 95.298H129.448V100.157H124.589z"
                    />
                    <path
                      fill="#FFF"
                      d="M109.066 106.367H113.925V111.226H109.066z"
                    />
                    <path
                      fill="#FFF"
                      d="M116.895 106.367H121.75399999999999V111.226H116.895z"
                    />
                    <path
                      fill="#FFF"
                      d="M116.895 119.325H121.75399999999999V128.504H116.895z"
                    />
                    <path
                      fill="#FFF"
                      d="M124.724 106.367H129.583V111.226H124.724z"
                    />
                    <path
                      fill="#3F3D56"
                      d="M134.443 10.799H136.603V19.978H134.443z"
                    />
                    <path
                      fill="#F9A826"
                      d="M171.429 58.853H175.209V64.453H171.429z"
                    />
                    <path
                      fill="#F99D1C"
                      d="M142.812 63.973H174.93800000000002V128.774H142.812z"
                    />
                    <path
                      fill="#3F3D56"
                      d="M174.938 63.973H187.896V128.774H174.938z"
                    />
                    <path
                      fill="#FFF"
                      d="M149.156 73.161H154.01500000000001V78.02H149.156z"
                    />
                    <path
                      fill="#FFF"
                      d="M156.985 73.161H161.84400000000002V78.02H156.985z"
                    />
                    <path
                      fill="#FFF"
                      d="M164.814 73.161H169.673V78.02H164.814z"
                    />
                    <path
                      fill="#FFF"
                      d="M149.291 84.229H154.15V89.088H149.291z"
                    />
                    <path
                      fill="#FFF"
                      d="M157.12 84.229H161.979V89.088H157.12z"
                    />
                    <path
                      fill="#FFF"
                      d="M164.949 84.229H169.80800000000002V89.088H164.949z"
                    />
                    <path
                      fill="#FFF"
                      d="M149.426 95.298H154.285V100.157H149.426z"
                    />
                    <path
                      fill="#FFF"
                      d="M157.255 95.298H162.114V100.157H157.255z"
                    />
                    <path
                      fill="#FFF"
                      d="M165.084 95.298H169.943V100.157H165.084z"
                    />
                    <path
                      fill="#FFF"
                      d="M149.561 106.367H154.42000000000002V111.226H149.561z"
                    />
                    <path
                      fill="#FFF"
                      d="M157.39 106.367H162.249V111.226H157.39z"
                    />
                    <path
                      fill="#FFF"
                      d="M157.39 119.325H162.249V128.504H157.39z"
                    />
                    <path
                      fill="#FFF"
                      d="M165.219 106.367H170.078V111.226H165.219z"
                    />
                    <path
                      fill="#3F3D56"
                      d="M174.938 58.853H177.09799999999998V64.293H174.938z"
                    />
                    <path
                      fill="#3F3D56"
                      d="M0.066 128.448H239.796V129.448H0.066z"
                    />
                    <circle cx="197.793" cy="109.077" r="7.03" fill="#B3C89B" />
                    <path
                      fill="#000"
                      d="M203.023 104.382a7.03 7.03 0 01-11.714 7.44 7.03 7.03 0 1011.714-7.44z"
                      opacity="0.2"
                    />
                    <path
                      fill="#3F3D56"
                      d="M197.812643 109.077435L197.832081 109.077435 198.179528 129.008774 197.445759 129.008774z"
                    />
                    <path
                      fill="#3F3D56"
                      d="M198.257 119.429H199.257V120.742H198.257z"
                      transform="rotate(62.234 198.43 120.086)"
                    />
                    <circle cx="218.462" cy="98.699" r="10.69" fill="#B3C89B" />
                    <path
                      fill="#000"
                      d="M226.414 91.56a10.691 10.691 0 01-17.814 11.314 10.691 10.691 0 1017.814-11.315z"
                      opacity="0.2"
                    />
                    <path
                      fill="#3F3D56"
                      d="M218.490979 98.6993926L218.520405 98.6993926 219.048999 129.008774 217.932958 129.008774z"
                    />
                    <path
                      fill="#3F3D56"
                      d="M219.167 114.442H220.167V116.439H219.167z"
                      transform="rotate(62.234 219.43 115.44)"
                    />
                    <circle cx="7.197" cy="108.807" r="7.03" fill="#B3C89B" />
                    <path
                      fill="#000"
                      d="M12.427 104.112a7.03 7.03 0 01-11.715 7.44 7.03 7.03 0 1011.715-7.44z"
                      opacity="0.2"
                    />
                    <path
                      fill="#3F3D56"
                      d="M7.21646794 108.807469L7.23590551 108.807469 7.58335208 128.738808 6.8495838 128.738808z"
                    />
                    <path
                      fill="#3F3D56"
                      d="M7.661 119.159H8.661V120.47200000000001H7.661z"
                      transform="rotate(62.234 7.835 119.816)"
                    />
                    <path
                      fill="#3F3D56"
                      d="M163.711 10.08l2.485-1.988c-1.93-.213-2.724.84-3.048 1.673-1.509-.626-3.15.195-3.15.195l4.972 1.805a3.763 3.763 0 00-1.259-1.686zM38.987 20.068l2.485-1.988c-1.93-.213-2.724.84-3.049 1.674-1.508-.627-3.15.194-3.15.194l4.973 1.805a3.763 3.763 0 00-1.26-1.685zM201.506 30.327l2.486-1.988c-1.931-.213-2.724.84-3.049 1.674-1.508-.627-3.15.194-3.15.194l4.972 1.805a3.763 3.763 0 00-1.259-1.685zM59.504 14.669l2.485-1.988c-1.93-.213-2.724.84-3.048 1.673-1.508-.626-3.15.195-3.15.195l4.972 1.805a3.763 3.763 0 00-1.259-1.685zM64.094 36.266l2.485-1.988c-1.93-.213-2.724.84-3.049 1.674-1.508-.627-3.15.194-3.15.194l4.972 1.805a3.763 3.763 0 00-1.258-1.685zM160.742 47.605l2.485-1.988c-1.93-.213-2.724.84-3.049 1.673-1.508-.626-3.15.195-3.15.195L162 49.29a3.763 3.763 0 00-1.258-1.685z"
                    />
                    <path
                      fill="#3F3D56"
                      d="M28.6164229 123.104612L11.8785152 123.104612 11.8785152 123.644544 14.4888189 123.644544 14.4888189 128.773903 15.0287514 128.773903 15.0287514 123.644544 25.1962205 123.644544 25.1962205 128.773903 25.736153 128.773903 25.736153 123.644544 28.6164229 123.644544z"
                    />
                    <path
                      fill="#3F3D56"
                      d="M45.624 125.264c-.66 0-1.31.174-1.881.505a4.045 4.045 0 00-6.758 3.005H49.39a3.777 3.777 0 00-3.766-3.51zM109.606 125.264c-.66 0-1.31.174-1.881.505a4.045 4.045 0 00-6.758 3.005h12.405a3.777 3.777 0 00-3.766-3.51zM151.451 125.264c-.66 0-1.31.174-1.881.505a4.045 4.045 0 00-6.758 3.005h12.405a3.777 3.777 0 00-3.766-3.51zM206.524 125.264c-.66 0-1.31.174-1.881.505a4.045 4.045 0 00-6.758 3.005h12.405a3.777 3.777 0 00-3.766-3.51zM232.98 125.264c-.66 0-1.309.174-1.88.505a4.045 4.045 0 00-6.758 3.005h12.405a3.777 3.777 0 00-3.766-3.51z"
                    />
                    <path
                      fill="#3F3D56"
                      d="M34.8256468 109.876265L30.5061867 109.876265 30.5061867 114.195726 32.5085264 114.195726 32.5085264 128.750146 33.0484589 128.750146 33.0484589 114.195726 34.8256468 114.195726z"
                    />
                    <path
                      fill="#3F3D56"
                      d="M11.911 121.489H28.649V122.489H11.911z"
                    />
                    <path
                      fill="#3F3D56"
                      d="M11.911 120.139H28.649V121.139H11.911z"
                    />
                    <path
                      fill="#3F3D56"
                      d="M11.911 118.79H28.649V119.79H11.911z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <p>
          Sorry, we currently do not service the entered PIN code area. However,
          we are continuously expanding. Do you still wish to proceed?
        </p>
        <button id="proceed-anyway-btn" className="bfcForm_bfcBtn__2mCXH">
          Proceed Anyway
        </button>
      </div>
    </div>
  </section>
      </Modal>
      
</section>
    </>
  )
}

export default Service
