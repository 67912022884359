import './counter.css'
import CountUp from 'react-countup';

function Counter() {
  return (
    <>
   <div
  className="container-fluid counter py-5"
  style={{
    background:
      "linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, 0.4)), url(https://image.slidesdocs.com/responsive-images/slides/0-brown-meeting-work-business-achievement-new-year-simple-concise-fashion-powerpoint-background_201fe19bd0__960_540.jpg) center center",
    backgroundSize: "cover"
  }}
>
  <div className="container py-5">
    <div className="text-center mx-auto pb-5" style={{ maxWidth: 800 }}>
      <h3 className="counter-h3-css"><span className='span-color'>Achievements </span></h3>
      <p className="text-white mb-0">
      Our designs have been honored, our projects successful, and our clients consistently praise our innovative approach and exceptional results
      </p>
    </div>
    <div className="row g-4">
    <div className="col-md-6 col-lg-6 col-xl-3">
        <div className="counter-item text-center border p-5">
          <i className="fas fa-file-invoice-dollar fa-4x text-white" />
          <h3 className="text-white my-4">Projects</h3>
          <div
            className="counter-counting text-center border-white w-100"
            style={{ borderStyle: "dotted", fontSize: 30 }}
          >
            <span
              className="text-p fs-2 fw-bold"
              data-toggle="counter-up"
            >
              <CountUp end={900} />
            </span>
            <span className="span-color fw-bold text-p">+</span>
          </div>
        </div>
      </div>
      {/* <div className="col-md-6 col-lg-6 col-xl-3">
        <div className="counter-item text-center border p-5">
          <i className="fas fa-file-invoice-dollar fa-4x text-white" />
          <h3 className="text-white my-4">Funds Collected</h3>
          <div
            className="counter-counting text-center border-white w-100"
            style={{ borderStyle: "dotted", fontSize: 30 }}
          >
            <span
              className="text-p fs-2 fw-bold"
              data-toggle="counter-up"
            >
              <CountUp end={531} />
            </span>
            <span className="span-color fw-bold text-p">+</span>
          </div>
        </div>
      </div> */}
      <div className="col-md-6 col-lg-6 col-xl-3">
        <div className="counter-item text-center border p-5">
          <i className="fas fa-heart fa-4x text-white" />
          <h3 className="text-white my-4">UNIQUE IDEAS</h3>
          <div
            className="counter-counting text-center border-white w-100"
            style={{ borderStyle: "dotted", fontSize: 30 }}
          >
            <span
              className="text-p fs-2 fw-bold"
              data-toggle="counter-up"
            >
              <CountUp end={1000} />
            </span>
            <span className="span-color fw-bold text-p">+</span>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-6 col-xl-3">
        <div className="counter-item text-center border p-5">
          <i className="fas fa-heart fa-4x text-white" />
          <h3 className="text-white my-4">Happy Clients</h3>
          <div
            className="counter-counting text-center border-white w-100"
            style={{ borderStyle: "dotted", fontSize: 30 }}
          >
            <span
              className="text-p fs-2 fw-bold"
              data-toggle="counter-up"
            >
              <CountUp end={900} />
            </span>
            <span className="span-color fw-bold text-p">+</span>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>

   
    </>
  )
}

export default Counter
