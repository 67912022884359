import React from 'react'
import Banner from '../Banner/Banner'
import Whychoose from '../whychoos/Whychoose'
import Service from '../service/Service'
import Aboutus from '../About/Aboutus'
import Counter from '../Counter/Counter'
import Testimonial from '../Testiminiall/Testimonial'
import Faq from '../FAQ/Faq'
import Footer from '../Footer/Footer'
import CardFaq from '../FaqCard/CardFaq'
import RowGallery from '../RowsDGallery/RowGallery'
import NewPage from '../../Page/NewPage'
import Indus from '../Indus/Indus'
import MetaTags from 'react-meta-tags';

function Home() {
  return (
    <>
      <MetaTags>
      <title>Top Interior Designers in Mira Road | Interior Concepts</title>
        <meta title="Best Farmhouse in Wada | Omkar Nature Farm" />
        <meta
          name="description"
          content="Discover the Top Interior Designers in Mira Road. Transform your home or office with expert designs and innovative solutions from the best professionals."
        />
        <meta
          name="keywords"
          content="Top Interior Designers in Mira Road,Best Interior Designer in Mira Road
,Interior Service in Mira Road,Interior Designer in Mira Road,Trunkey Contractor in Mira Road,Civil Contractor in Mira Road,Modular Kitchen Designer in Mira Road,Furniture  Designer in Mira Road,Interior work,Interior Designer near Mira Road"
        />
        <link rel="canonical" href="https://interiorconcepts.org/ Best Farmhouse in Wada" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Top Interior Designers in Mira Road | Interior Concepts" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://interiorconcepts.org/Farmhouse in Wada"/>
        <meta property="og:description" content="Discover the Top Interior Designers in Mira Road. Transform your home or office with expert designs and innovative solutions from the best professionals. " />
        <meta property="og:image" content="https://interiorconcepts.org/static/media/omkar-nature.87735681decd4a0e08d7.jpeg" />       
      </MetaTags>
      <Banner/>
      <Whychoose/>
      <Service/>
      <Aboutus/>
      <Counter/>
      {/* <Faq/> */}
      <CardFaq/>
      <Testimonial/>
      <RowGallery/>
      <Indus/>
      <NewPage/>
      <Footer/>
    </>
  )
}

export default Home
