import React from 'react'
import "./about.css"
import aboutimf from "../../Assets/about-banner.jpeg"
import { Link } from 'react-router-dom'
import MetaTags from 'react-meta-tags';

function Aboutp() {
  return (
    <>
    <MetaTags>
      <title>Best Interior Designer in Mira Road | Interior Concepts</title>
        <meta title="Best Farmhouse in Wada | Omkar Nature Farm" />
        <meta
          name="description"
          content="Elevate your space with the Best Interior Designer in Mira Road. From conceptualization to execution, our team ensures stunning and functional designs.
"
        />
        <meta
          name="keywords"
          content="Top Interior Designers in Mira Road,Best Interior Designer in Mira Road
,Interior Service in Mira Road,Interior Designer in Mira Road,Trunkey Contractor in Mira Road,Civil Contractor in Mira Road,Modular Kitchen Designer in Mira Road,Furniture  Designer in Mira Road,Interior work,Interior Designer near Mira Road"
        />
        <link rel="canonical" href="https://interiorconcepts.org/ Best Farmhouse in Wada" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Best Interior Designer in Mira Road | Interior Concepts" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://interiorconcepts.org/Farmhouse in Wada"/>
        <meta property="og:description" content="Elevate your space with the Best Interior Designer in Mira Road. From conceptualization to execution, our team ensures stunning and functional designs.
 " />
        <meta property="og:image" content="https://interiorconcepts.org/static/media/omkar-nature.87735681decd4a0e08d7.jpeg" />       
      </MetaTags>
    <section>
    
      <div
  className="breadcrumb-area breadcrumb-padding bg-img"
  style={{ backgroundImage: `url(${aboutimf})` }}
>
  <div className="container">
    <div className="breadcrumb-content text-center">
      <h2>About Us</h2>
      {/* <ul>
        <li>
            <Link to="/">
            Home
            </Link>
          
        </li>
        <li>
          <i className="fa fa-angle-right" />
        </li>
        <li>Contact Us</li>
      </ul> */}
    </div>
  </div>
</div>
<div className="container">
<div  className="static-page-container  ">
  <div  className="landing-page-banner relative">
    <div >
      <div
        data-v-798b613d=""
        
        data-id="pageBreadCrumb"
        className="breadcrumb caption_default secondary-dark-color flex"
      >
        {/* <div data-v-798b613d="" className="flex-shrink-0">
          <Link to="/"
            data-v-798b613d=""
            
            className="link truncate nuxt-link-active"
          >
            Home
          </Link>{" "}
          <span data-v-798b613d="" className="crumb-separator">
            /
          </span>
        </div> */}
        <div data-v-798b613d="" className="truncate">
          {/**/}{" "}
          <span data-v-798b613d="" className="crumb-separator">
            {/* <Link to="/workpage">
            
            Work
            </Link> */}
          </span>
        </div>
      </div>
    </div>{" "}
    <div  className="pb-10 lg:pb-20">
      <div  className="wrapper-class">
        <div
          
          className="flex justify-start md:justify-center story-head-section"
        >
          <div
            
            className="story-head relative flex items-center"
          >
            <div  className="red-tape absolute" />{" "}
            <h1 className="about-heading">How it  <span className='interial-con'>started</span></h1>
          </div>
        </div>{" "}
        <p
          
          className="story-description body_large text-center"
        >
          Welcome to Interior Concept, the ideal site for becoming the Best Interior Designer on Mira Road. We believe that a well-designed space improves not just its aesthetic appeal but also your quality of life. Our objective is to inspire and support you in creating environments that truly represent your unique personality and preferences.
          <br />
          <br />
          With our diverse collection of design ideas and inspirations, you may embark on an unlimited creative journey. Whether you favor modern minimalism, timeless elegance, or daring diverse designs, our expertly chosen galleries and mood boards will spark your creativity. Discover the latest trends, creative solutions, and timeless designs to suit every taste and need.
        </p>
        <p
          
          className="story-description body_large text-center"
        >
          Interior Concept is your doorway to information and expertise. Our blog includes insightful articles, practical recommendations, and expert ideas on all aspects of interior design. Our tools are designed to help both novices and seasoned professionals understand colour theory, master spatial layouts, and choose the appropriate furnishings and decor.
          <br />
          <br />
          Join a vibrant network of other interior designers, professionals, and experts. Share your efforts, receive valuable comments, and discuss ideas with like-minded people. Participate in dynamic discussions, and webinars, and stay up to date on industry events and workshops using our forums and social media channels
        </p>
        <p
          
          className="story-description body_large text-center"
        >
          Interior Concept is committed to helping you grow and succeed as the Best Interior Designer in Mira Road. Whether you're a homeowner embarking on a makeover adventure, an aspiring interior designer refining your trade, or a seasoned professional looking for new challenges, we offer opportunities for progress. Enrich your creative journey by enrolling in one of our online courses, certification programs, or personalized consultations.

Join us at Interior Concept to convert your surroundings into inspirational sanctuaries. Explore, learn, network, and grow with us; your journey to becoming the Best Interior Designer in Mira Road begins here.

          <br />
        </p>
      </div>
    </div>
  </div>
</div>
 {/* <div  className=" text-center  founder-sec  ">
  <div  className="wrapper-clas ">
    <h2  className=" about-heading text-center mb-4 mb-5">
      Meet our founders
    </h2>{" "}
   <div className="cr-7">
   <div className="row">
    <div className="col-lg-6 col-md-12 col-sm-12 founder-section">
    <div
        data-v-66955cb0=""
        
        className="profile-wrapper mb-5 mb-0 mr-8"
      >
        <img
          data-v-66955cb0=""
          loading="lazy"
          src="https://m.media-amazon.com/images/I/31Cd9UQp6eL._AC_UF1000,1000_QL80_.jpg"
          width={200}
          height={200}
          srcSet="https://m.media-amazon.com/images/I/31Cd9UQp6eL._AC_UF1000,1000_QL80_.jpg"
          sizes="200px"
          fetchpriority="auto"
          className="rounded-full m-auto loaded"
        />{" "}
        <div data-v-66955cb0="" className="flex flex-col sm:items-center mt-3">
          <p data-v-66955cb0="" className="subtitle_large">
            Miss KNOWN
          </p>{" "}
          <p
            data-v-66955cb0=""
            className="subtitle_default on-surface-70-color mb-3"
          >
            Co-founder and CEO
          </p>{" "}
          <p data-v-66955cb0="" className="body_default">
            <strong>A dreamer and a chess master of the startup world,</strong>{" "}
           Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem corporis fugiat rerum ex dignissimos ad saepe laboriosam hic exercitationem fugit doloremque incidunt corrupti nisi eum culpa repellendus tempore cumque commodi iusto possimus, sint iste nulla. Sapiente a doloribus quas dicta, quibusdam accusantium explicabo aliquam eligendi fugiat 
            <br />
            <br />
            corrupti nisi eum culpa repellendus tempore cumque commodi iusto possimus, sint iste nulla. Sapiente a doloribus quas dicta, quibusdam accusantium explicabo aliquam eligendi fugiat 
          </p>
        </div>
      </div>
    </div>
    <div className=" col-lg-6 col-md-12 col-sm-12 founder-section">
    <div
        data-v-66955cb0=""
        
        className="profile-wrapper mb-6 sm:mb-0 sm:mr-8"
      >
        <img
          data-v-66955cb0=""
          loading="lazy"
          src="https://images.livspace-cdn.com/w:200/h:200/plain/https://d3gq2merok8n5r.cloudfront.net/bumblebee/in/about-us-1629737930-WrXkD/mobile-1629737947-8cnvs/founders-1629749026-4Dd76/rk-mobile-1632298059-v71q6.jpg"
          width={200}
          height={200}
          srcSet="https://m.media-amazon.com/images/I/31Cd9UQp6eL._AC_UF1000,1000_QL80_.jpg"
          sizes="200px"
          fetchpriority="auto"
          className="rounded-full m-auto loaded"
        />{" "}
        <div data-v-66955cb0="" className="flex flex-col sm:items-center mt-3">
          <p data-v-66955cb0="" className="subtitle_large">
            MISS KNOWN
          </p>{" "}
          <p
            data-v-66955cb0=""
            className="subtitle_default on-surface-70-color mb-3"
          >
            Co-founder and COO
          </p>{" "}
          <p data-v-66955cb0="" className="body_default">
            <strong>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis, nihil.</strong> Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem corporis fugiat rerum ex dignissimos ad saepe laboriosam hic exercitationem fugit doloremque incidunt corrupti nisi eum culpa repellendus tempore cumque commodi iusto possimus, sint iste nulla. Sapiente a doloribus quas dicta, quibusdam accusantium explicabo aliquam eligendi fugiat
            innovate technology at Livspace.
            <br />
            <br />
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem corporis fugiat rerum ex dignissimos ad saepe laboriosam hic exercitationem 
          </p>
        </div>
      </div>
    </div>
     
    </div>
  </div>
  </div>
  </div> */}
  </div>
  </section>

    </>
  )
}

export default Aboutp
