import React from 'react'
import "./contact.css"
import { Link } from 'react-router-dom'
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { Form, Input, Button } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import $ from "jquery"


function Contact() {
  const submitForm = (values) => {
    console.log("values",values);

    var body =  '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#dc9d7e">Interior Concepts</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#dc9d7e"><strong>Name:</strong></td><td style="text-align:left">' + values.name + '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Email:</strong></td><td style="text-align:left">' + values.email + '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Subject:</strong></td><td style="text-align:left">' + values.subject + '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Message:</strong></td><td style="text-align:left">' + values.message + '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Mobile Number:</strong></td><td style="text-align:left">' + values.mobileNo + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Interior Concepts</p></div></body></html>';

        $.post("https://skdm.in/server/v1/send_lead_mail.php", {
            toEmail: "ajay.interiorconcepts@gmail.com",
            fromEmail: "skdmlead@gmail.com",
            bccMail: "skdmlead@gmail.com",
            mailSubject: "New Customer Lead",
            mailBody: body,
            accountName: "Interior Concepts",
            accountLeadSource: "interiorconcepts.org",
            accountLeadName: values.name,
            accountLeadEmail: values.email,
            accountLeadPhone: values.contact,
        },

            function (dataa, status) {
                console.log('data :' + dataa);
                console.log("name:" + values);

            });

        alert("Your Form has Submitted Our team will contact with You  soon.");
        values.preventDefault()
        return false;
  };
 


  return (
    <>
      <div
  className="breadcrumb-area breadcrumb-padding bg-img"
  style={{ backgroundImage: "url(https://htmldemo.net/drior/drior/assets/images/bg/bg-2.jpg)" }}
>
  <div className="container">
    <div className="breadcrumb-content text-center">
      <h2>Contact Us</h2>
      <ul>
        <li>
            <Link to="/">
            Home
            </Link>
          
        </li>
        <li>
          <i className="fa fa-angle-right" />
        </li>
        <li>Contact Us</li>
      </ul>
    </div>
  </div>
</div>
<div className="d-jangoo">
Looking for an interior designer near Mira Road? You're in the right place! Our expert team specialises in transforming spaces into stunning environments that reflect your unique style and personality. Whether you're renovating your home or designing a new office, our professional interior designers bring creativity and precision to every project. Contact us today to schedule a consultation with an interior Designer near Mira Road. We are dedicated to providing personalised services to meet your specific needs and preferences. Reach out now to start your journey towards a beautifully designed space you'll love.
</div>
  <section>
    <div className="container">
    <div className="section section-padding">
  <div className="container">
    <div className="row">
      <div className="col-lg-5 col-md-6">
        <div className="contact-info-wrap">
          <div className="single-contact-info-wrap">
            <div className="info-icon">
              <i className="dlicon ui-1_home-51" />
            </div>
            <div className="info-content">
              <h3 className="title">Address</h3>
              <p className="width">
              Shop no -15, Celeste, JP infra, near, Vinay Nagar Rd, Kashimira, Mira Road East, Thane, Mira Bhayandar, Maharashtra 401107
              </p>
            </div>
          </div>
          <div className="single-contact-info-wrap">
            <div className="info-icon">
              <i className="dlicon ui-2_phone" />
            </div>
            <div className="info-content">
              <h3 className="title">Phone</h3>
              <p>
                {" "}
                Mobile: <span>(+91)093727 70662</span>
              </p>
              <p>
                {" "}
                {/* Hotline: <span>1802550 - 1102456</span> */}
              </p>
            </div>
          </div>
          <div className="single-contact-info-wrap">
            <div className="info-icon">
              <i className="dlicon ui-1_email-85" />
            </div>
            <div className="info-content">
              <h3 className="title">Email</h3>
              <p>ajay.interiorconcepts@gmail.com</p>
              {/* <p>info@example.com</p> */}
            </div>
          </div>
          <div className="single-contact-info-wrap">
            <div className="info-icon">
              <i className="dlicon ui-2_share" />
            </div>
            <div className="info-content">
              <h3 className="title">Follow us</h3>
              <div className="social-icon-style mt-4">
                <Link className="facebook" to="https://www.facebook.com/interiorconcepts05">
                  <i className="fa fa-facebook"><FaFacebookF /></i>
                </Link>
                {/* <a className="twitter" href="#">
                  <i className="fa fa-twitter"><FaTwitter /></i>
                </a> */}
                <Link className="google-plus" to="https://www.instagram.com/interior_concepts_/">
                  <i className="fa fa-google-plus"><FaInstagram /></i>
                </Link>
                {/* <a className="behance" href="#">
                  <i className="fa fa-behance" />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-7 col-md-6">
        <div className="contact-from-wrap">
        <div className="row">
                <Form
                className="php-email-form"
                layout="vertical"
                onFinish={submitForm}
              >
                <div className="row">
                  <div className="form-group col-md-6">
                    <Form.Item 
                    name="name" 
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your name!',
                      },
                    ]}
                    >
                      <Input
                        placeholder="Enter a name"
                        className="form-control custName custNameValidation"
                      />
                    </Form.Item>
                  </div>
                  <div className="form-group col-md-6">
                    <Form.Item 
                    name="subject" 
                    label="Subject"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter subject!',
                      },
                    ]}
                    >
                      <Input
                        placeholder="Enter subject"
                        className="form-control custPhone custPhoneValidation"
                      />
                    </Form.Item>
                  </div>
                <div className="form-group col-lg-6">
                  <Form.Item 
                  name="email"
                   label="Email"
                   rules={[
                    {
                      required: true,
                      message: 'Please enter email!',
                    },
                  ]}
                   >
                    <Input
                      placeholder="Enter e-mail id"
                      className="form-control custEmail custEmailValidation"
                    />
                  </Form.Item>
                </div>
                <div className="form-group col-lg-6">
                  <Form.Item 
                  name="mobileNo"
                   label="mobileNo"
                   rules={[
                    {
                      required: true,
                      message: 'Please enter mobile:no!',
                    },
                  ]}
                   >
                    <Input
                      placeholder="Enter mobile:no"
                      className="form-control custEmail custEmailValidation"
                    />
                  </Form.Item>
                </div>
                </div>
                <div className="form-group">
                  <Form.Item
                   name="message"
                    label="Message"
                    rules={[
                      {
                        required: true,
                        message: 'Please Your message!',
                      },
                    ]}
                    >
                    <Input.TextArea
                      className="form-control custMessage"
                      placeholder="Enter message"
                    />
                  </Form.Item>
                </div>
                <div className="text-center cutomSubmit">
                  <Form.Item>
                    <Button htmlType="submit">Send Message</Button>
                  </Form.Item>
                </div>
              </Form>
                </div>
          <p className="form-messege" />
        </div>
      </div>
    </div>
    <div className="map  mt-md-12">
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15064.086451811992!2d72.8843507!3d19.2814263!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b1dcdc2e167d%3A0x9443bfe12c66935b!2sInterior%20Concepts!5e0!3m2!1sen!2sin!4v1716381272600!5m2!1sen!2sin" />
    </div>
    
  </div>
</div>

    </div>
  </section>
    </>
  )
}

export default Contact
