import './App.css';
import Aboutp from './Components/AboutP/Aboutp';
import Home from './Components/Home/Home';
import Navi from './Components/Navigat/Navi';
import { Routes, Route} from 'react-router-dom';
import AboutPage from './Page/AboutPage';
import ServicePage from './Page/ServicePage';
import ServiceData from './Components/JsonData/Service';
import ContectPage from './Page/ContectPage';
import WorkPage from './Page/WorkPage';
import NewPage from './Page/NewPage';


function App() {
  return (
    <>
    <Navi/>
   
    <Routes> 
    <Route path='/' element ={<Home/>}/>
    <Route path='/best-interior-designer-mira-road' element ={<AboutPage/>}/>
   
   

   {
      ServiceData.map((v,i)=>{
        return(<>
        <Route 
        key={i}
        exact
      path={v.slug}
      element={<ServicePage/>}
        />
        </>)
      })
   }
   <Route path='/interior-work' element={<WorkPage/>}/>
  <Route path='/interior-designer-near-mira-road' element={<ContectPage/>} />
    </Routes>
  
    </>
  );
}

export default App;
