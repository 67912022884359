import React, { useState } from 'react';
import about from "../../Assets/Kitchen/kitchen5.png";


import "./about.css";

function Aboutus() {
  // State to manage the visibility of additional content
  const [isExpanded, setIsExpanded] = useState(false);

  // Toggle the expanded state
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <section className="AboutUs-Section-1">
        <div className="container">
          <div className="AboutUs-Section-1-con">
            <div className="row">
              <div className="col-md-8">
                <div className="About-Section-1-Details">
                  <div className="About-Section-1-Details-con">
                   
                      <h2 className="Smalltitle">About <span className='interial-con'>Us</span></h2>
                   
                    <h3 className="Headtitle">
                      Join Us in Exploring, Learning, Connecting, and Growing
                    </h3>
                    <p>
                    Greetings from interior concept The Best Interior Designer on Mira Road is the perfect location for the best interior design services. We believe that a well-designed space improves not just its aesthetic appeal but also your quality of life. Our objective is to inspire and support you in creating environments that truly represent your unique personality and preferences.
                    </p>
                    <p>
                    With our diverse collection of design ideas and inspirations, you may embark on an unlimited creative journey. Whether you favour modern minimalism, timeless elegance, or daring diverse designs, our expertly chosen galleries and mood boards will spark your creativity. Discover the latest trends, creative solutions, and timeless designs to suit every taste and need.
                    </p>
                    <p>
                    Interior Concept is your doorway to information and expertise. Our blog includes insightful articles, practical recommendations, and expert ideas on all aspects of interior design. Our tools are designed to help both novices and seasoned professionals understand colour theory, master spatial layouts, and choose the appropriate furnishings and decor.
                    </p>
                   
                    <p>
                
                      {!isExpanded && <span>... <button onClick={toggleExpansion} className="btn-enq">Learn More</button></span>}
                    </p>
                    {isExpanded && (
                      <div className="expanded-content">
                        <p>
                        <p>
                    Join a vibrant network of other interior designers, professionals, and experts. Share your efforts, receive valuable comments, and discuss ideas with like-minded people. Participate in dynamic discussions, and webinars, and stay up to date on industry events and workshops using our forums and social media channels.
                    </p>
                        </p>
                        <p>
                        Interior Concept is committed to helping you grow and succeed as the Best Interior Designer in Mira Road. Whether you're a homeowner embarking on a makeover adventure, an aspiring interior designer refining your trade, or a seasoned professional looking for new challenges, we offer opportunities for progress. Enrich your creative journey by enrolling in one of our online courses, certification programs, or personalized consultations.

Join us at Interior Concept to convert your surroundings into inspirational sanctuaries. Explore, learn, network, and grow with us; your journey to becoming the Best Interior Designer in Mira Road begins here.

                        </p>
                        <button onClick={toggleExpansion} className="btn-enq">Show Less</button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="About-Section-1-Image-con">
                  <div className="About-Section-1-Image">
                    <img src={about} alt="About Us" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Aboutus;
