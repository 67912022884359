import React from 'react'
import Contact from '../Components/ContactP/Contact'
import Footer from '../Components/Footer/Footer'

function ContectPage() {
  return (
    <>
      <Contact/>
      <Footer/>
    </>
  )
}

export default ContectPage
