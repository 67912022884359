import Marquee from "react-fast-marquee";
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import fabindia from "../Assets/fabindia-logo.png";
import adsidas from "../Assets/Logos/adidas-png-logo (1).png"
import kip from "../Assets/Logos/Kipling.png"
import lee from "../Assets/Logos/Lee.png"
import Peter from "../Assets/Logos/Peter-England.png"
import flying from "../Assets/Logos/flying-machine.jpg"
import lenkart from "../Assets/Logos/lenkart.png"
import levis from "../Assets/Logos/levis.png"
import lodha from "../Assets/Logos/lodha-group-logo-.png"
import muse from "../Assets/Logos/muse.png"
import nautica from "../Assets/Logos/nautica.png"
import puma from "../Assets/Logos/puma.png"
import tata from "../Assets/Logos/tata-logo.png"
import tommy from "../Assets/Logos/tommy-hilfiger.png"
import van from "../Assets/Logos/van-heusen.png"
import USPoloAssn from "../Assets/Logos/USPoloAssn.png"
function NewPage() {
  return (
   
      <>
        {/* Mobile Menu */}
        <div
          className="modal fade modal-search"
          id="myModal"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        ></div>
        <div id="page">
          <div id="skrollr-body">
            <section
              data-vc-full-width="true"
              data-vc-full-width-init="true"
              className="vc_section vc_custom_1553496770482 vc_section-has-fill"
              style={{
                position: "relative",
                left: 15,
                boxSizing: "border-box",
                width: 1519,
                paddingLeft: 0,
                paddingRight: 0,
              }}
            ></section>
            <div className="vc_row-full-width vc_clearfix" />

            <section className="wpb_row vc_row-fluid vc_custom_1562570814565 vc_row-has-fill">
              <div className="container">
                <div className="row">
                  <div className="wpb_column vc_column_container vc_col-sm-12"></div>
                  <div className="wpb_column vc_column_container vc_col-sm-12 vc_hidden-sm vc_hidden-xs">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper ">
                        <div className="wpb_images_carousel wpb_content_element vc_clearfix">
                          <div className="wpb_wrapper">
                            <div
                              id="vc_images-carousel-1-1568962650"
                              data-ride="vc_carousel"
                              data-wrap="true"
                              style={{ width: "100%" }}
                              data-interval={3000}
                              data-auto-height="yes"
                              data-mode="horizontal"
                              data-partial="false"
                              data-per-view={5}
                              data-hide-on-end="false"
                              className="vc_slide vc_images_carousel vc_per-view-more vc_per-view-5 vc_build"
                            >
                              <div className="vc_carousel-inner">
                                <div className="wpb_wrapper ">
                                  <h2 className="titlee" style={{}}>
                                    {" "}
                                    OUR CLIENTS
                                  </h2>
                                  <div className="divider divider-1">
                                    <svg className="svg-triangle-icon-container">
                                      <polygon
                                        className="svg-triangle-icon"
                                        points="6 11,12 0,0 0"
                                      />
                                    </svg>
                                  </div>
                                </div>

                                <Marquee>
                                  <ul className="markuee-ul">
                                    <li>
                                      <div className="pmg-gapp">
                                        <img
                                          src={adsidas}
                                          alt=""
                                        />
                                      </div>
                                    </li>
                                    <li>
                                      <div className="pmg-gapp">
                                        <img
                                          src={kip}
                                          alt=""
                                        />
                                      </div>
                                    </li>
                                    <li>
                                      <div className="pmg-gapp">
                                        <img
                                          src={lee}
                                          alt=""
                                        />
                                      </div>
                                    </li>
                                    <li>
                                      <div className="pmg-gapp">
                                        <img
                                          src={Peter}
                                          alt=""
                                        />
                                      </div>
                                    </li>
                                    <li>
                                      <div className="pmg-gapp">
                                        <img
                                          src={lenkart}
                                          alt=""
                                        />
                                      </div>
                                    </li>
                                    <li>
                                      <img
                                        src={levis}
                                        alt=""
                                      />
                                    </li>
                                    <li>
                                      <div className="pmg-gapp">
                                        <img
                                          src={flying}
                                          alt=""
                                        />
                                      </div>
                                    </li>
                                    <li>
                                      <div className="pmg-gapp">
                                        <img
                                          src={lodha}
                                          alt=""
                                        />
                                      </div>
                                    </li>
                                    <li>
                                      <div className="pmg-gapp">
                                        <img
                                          src={muse}
                                          alt=""
                                        />
                                      </div>
                                    </li>
                                    <li>
                                      <div className="pmg-gapp">
                                        <img
                                          src={fabindia}
                                          alt=""
                                        />
                                      </div>
                                    </li>
                                    <li>
                                      <div className="pmg-gapp">
                                        <img
                                          src={nautica}
                                          alt=""
                                        />
                                      </div>
                                    </li>
                                    <li>
                                      <div className="pmg-gapp">
                                        <img
                                          src={puma}
                                          alt=""
                                        />
                                      </div>
                                    </li>
                                    <li>
                                      <div className="pmg-gapp">
                                        <img
                                          src={tata}
                                          alt=""
                                        />
                                      </div>
                                    </li>
                                    <li>
                                      <div className="pmg-gapp">
                                        <img
                                          src={tommy}
                                          alt=""
                                        />
                                      </div>
                                    </li>
                                    <li>
                                      <div className="pmg-gapp">
                                        <img
                                          src={van}
                                          alt=""
                                        />
                                      </div>
                                    </li>
                                    <li>
                                      <div className="pmg-gapp">
                                        <img
                                          src={USPoloAssn}
                                          alt=""
                                        />
                                      </div>
                                    </li>
                                  </ul>
                                </Marquee>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* End page */}

        <link
          rel="stylesheet"
          id="vc_carousel_css-css"
          href="//amiconstructions.in/wp-content/cache/wpfc-minified/lnzvmzld/hq986.css"
          type="text/css"
          media="all"
        />
        {/*   Unlimited Addons Scripts  */}
      </>
   
  );
}

export default NewPage;
