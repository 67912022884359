import React from 'react'
import "./Indus.css"
import { FaHotel } from "react-icons/fa";
import { MdOutlineCastForEducation } from "react-icons/md";
import { MdCorporateFare } from "react-icons/md";
import { MdOutlineRealEstateAgent } from "react-icons/md";
import { GiFamilyHouse } from "react-icons/gi";
import { FaStore } from "react-icons/fa";
import { FaHospital } from "react-icons/fa";
import { BiBuildingHouse } from "react-icons/bi";



function Indus() {
  return (
    <>
      <section>
        <div className="container">
        <div className="wpb_wrapper ">
      <h2 className="titlee" style={{}}>
        {" "}
        INDUSTRIES WE SERVES
      </h2>
      <div className="divider divider-1">
        <svg className="svg-triangle-icon-container">
          <polygon className="svg-triangle-icon" points="6 11,12 0,0 0" />
        </svg>
      </div>
    </div>
        </div>
      </section>

      <section className='indus-mai'>
        <div className="container">
          <div className="indus-main">
            <div className="row">
              <div className="col-lg-3">
                <div className="pmg-iconn text-center">
                  <i><FaHotel /></i>
                  <p>Hotel</p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="pmg-iconn text-center">
                  <i><MdOutlineCastForEducation /> </i>
                  <p>Education Institutes</p>
                </div>
              </div>
              <div className="col-lg-3 text-center">
                <div className="pmg-iconn">
                  <i><MdCorporateFare /></i>
                  <p>Corporate Offices</p>
                </div>
              </div>
              <div className="col-lg-3 text-center">
                <div className="pmg-iconn ">
                  <i><MdOutlineRealEstateAgent />
                  </i>
                  <p>Realty</p>
                </div>
              </div>
              <div className="col-lg-3 text-center">
                <div className="pmg-iconn ">
                  <i><GiFamilyHouse /></i>
                  <p>Clubhouses</p>
                </div>
              </div>
              <div className="col-lg-3 text-center">
                <div className="pmg-iconn ">
                 <i><FaStore />
                 </i>
                  <p>Retail Stores</p>
                </div>
              </div>
              <div className="col-lg-3 text-center">
                <div className="pmg-iconn ">
                  <i><FaHospital />
                  </i>
                  <p>Hospitals</p>
                </div>
              </div>
              <div className="col-lg-3 text-center">
                <div className="pmg-iconn ">
                  <i><BiBuildingHouse /></i>
                  <p>High-End Lobbies</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Indus
