import Testimonialtwo from '../Testimonial2/Testimonialtwo'
import './testi.css'

function Testimonial() {
  return (
    <>
    <section>
        <div className="">
      <div
  className="section section-padding bg-img  opacity-point-7"
  style={{ backgroundImage: "url(  )" }}
>
 
   
    <Testimonialtwo/>
  </div>
</div>

</section>
    </>
  )
}

export default Testimonial
