import React from 'react'
import Servicep from '../Components/ServiceP/Servicep'
import Testimonial from '../Components/Testiminiall/Testimonial'
import Footer from "../Components/Footer/Footer"

function ServicePage() {
  return (
    <>
      <Servicep/>
      <Testimonial/>
      <Footer/>
    </>
  )
}

export default ServicePage
