import React, { useLayoutEffect, useState } from "react";
import WorkTab from "../JsinData1/WorkTab";
import { Link } from "react-router-dom";
import { Image } from "antd";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import "./tabWork.css";
import MetaTags from 'react-meta-tags';


function WorkP() {
  const [items, setItems] = useState([]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    setItems(WorkTab);
  }, []);

  const filterItems = (category) => {
    if (category === "All") {
      setItems(WorkTab);
    } else {
      const updatedItems = WorkTab.filter((item) => item.category === category);
      setItems(updatedItems);
    }
  };

  return (
    <>
    <MetaTags>
      <title>Interior work | Interior Concepts</title>
        <meta title="Best Farmhouse in Wada | Omkar Nature Farm" />
        <meta
          name="description"
          content="Transform your home or office with expert Interior Work services. From innovative designs to meticulous execution, our professionals ensure a stunning space."
        />
        <meta
          name="keywords"
          content="Top Interior Designers in Mira Road,Best Interior Designer in Mira Road
,Interior Service in Mira Road,Interior Designer in Mira Road,Trunkey Contractor in Mira Road,Civil Contractor in Mira Road,Modular Kitchen Designer in Mira Road,Furniture  Designer in Mira Road,Interior work,Interior Designer near Mira Road"
        />
        <link rel="canonical" href="https://interiorconcepts.org/ Best Farmhouse in Wada" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Interior work | Interior Concepts" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://interiorconcepts.org/Farmhouse in Wada"/>
        <meta property="og:description" content="Transform your home or office with expert Interior Work services. From innovative designs to meticulous execution, our professionals ensure a stunning space.
 " />
        <meta property="og:image" content="https://interiorconcepts.org/static/media/omkar-nature.87735681decd4a0e08d7.jpeg" />       
      </MetaTags>
      <div
        className="breadcrumb-area breadcrumb-padding bg-img"
        style={{
          backgroundImage:
            "url(https://htmldemo.net/drior/drior/assets/images/bg/bg-2.jpg)",
        }}
      >
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h2>Work</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <i className="fa fa-angle-right" />
              </li>
              <li>Contact Us</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="homeScroll4">
        <div className="homeSection" id="home">
          <div className="homeText">
            <h1 style={{ color: "#fff" }}>Gallery</h1>
          </div>
        </div>
      </div>

      <div className="container">
        <section className="myGallery">
          <div className="titleSection">
            <h3>Our <span className="span-color">Work</span> </h3>
          </div>
          <div className="filters filter-button-group">
            <ul>
              <li className="active">
                <Link to="#all" onClick={() => filterItems("All")}>
                  All
                </Link>
              </li>
              <li>
                <Link
                  to="#Interior"
                  onClick={() => filterItems("Interior")}
                >
                  Interior Designing
                </Link>
              </li>
              <li>
                <Link
                  to="#Trunkey"
                  onClick={() => filterItems("Trunkey")}
                >
                  Trunkey Contractor
                </Link>
              </li>
              <li>
                <Link to="#Civil" onClick={() => filterItems("Civil")}>
                  Civil
                </Link>
              </li>
              <li>
                <Link
                  to="#Modular "
                  onClick={() => filterItems("Modular")}
                >
                  Modular Kitchen
                </Link>
              </li>
              <li>
                <Link to="#furniture" onClick={() => filterItems("furniture")}>
                  Furniture
                </Link>
              </li>
            </ul>
          </div>


          <div className="d-jangoo">
          Interior design is essential for changing any room into a functional and visually appealing setting. Whether it's a residential property or a commercial establishment, the quality of interior work can significantly impact the overall ambiance; and utility of the area. Skilled professionals meticulously plan and execute interior work, ensuring every detail aligns with the desired design and functionality. From installing lighting and fixtures to crafting custom cabinetry and finishes, interior work requires a blend of creativity and precision. Ultimately, excellent interior work not only enhances the visual appeal but also adds value and comfort to any space.
          </div>

          <Box>
            <ImageList variant="masonry" cols={4} gap={8}>
              {items.map((v, i) => (
                <ImageListItem key={v.imageUr1}>
                  <Image
                    srcSet={`${v.imageUr1}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    src={`${v.imageUr1}?w=248&fit=crop&auto=format`}
                    alt={v.title}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
        </section>
      </div>
    </>
  );
}

export default WorkP;
