import interiorOne from "../../Assets/interior/inter.png"
import interiorTwo from "../../Assets/interior/inter.png"
import interiorThree from "../../Assets/interior/F4.jpg"
import interiorFour from "../../Assets/interior/CAM 3.jpg"
// ****************************trumkey****************************************************
import trunkeyOne from "../../Assets/Trunkey/trunkey4.png"
import trunkeyTwo from "../../Assets/Trunkey/trunkey3.png"
import trunkeyThree from "../../Assets/Trunkey/trunkey7.png"
import trunkeyFour from "../../Assets/Trunkey/trunkey1.png"
// *****************************civil**************************************************************
import civil1 from "../../Assets/Civil/civil1.png"
import civil2 from "../../Assets/Civil/civil2.png"
import civil3 from "../../Assets/Civil/civil3.png"
import civil4 from "../../Assets/Civil/civil4.png"
// ******************************modular**************************************************************
import modular1 from "../../Assets/Kitchen/kitchen6.png"
import modular2 from "../../Assets/Kitchen/kitchen5.png"
import modular3 from "../../Assets/Kitchen/kitchen1.png"
import modular4 from "../../Assets/Kitchen/kitchen2.png"
// ************************************Furniture******************************************************
import furniture1 from "../../Assets/Furniture/fur6.png"
import furniture2 from "../../Assets/Furniture/fur5.png"
import furniture3 from "../../Assets/Furniture/fur3.png"
import furniture4 from "../../Assets/Furniture/fur1.png"
const WorkTab = [
    // [
              // {
              //     id: 1,
              //     title: "Interior Designing",
              //     category:"Interior",
              //     imageUr1: interiorOne,
              //     width: 4,
              //     height: 1,
                  
              //   },
              {
                  id: 2,
                  title: "Interior Designing",
                  category:"Interior",
                  imageUr1:interiorTwo,
                  width: 1,
                 height: 3,
                 
                  
                  
                },
              {
                  id: 3,
                  title: "Interior Designing",
                  category:"Interior",
                  imageUr1: interiorThree    ,       
                   width: 4,
                  height: 2,
                  
                },
              {
                  id: 4,
                  title: "Interior Designing",
                  category:"Interior",
                  imageUr1: interiorFour  ,         
                  width: 4,
                  height: 1,
                  
                },
             
                // ******************************************Trunkey************************************************************
      
               
      
                {
                  id:1,
                  title: "Trunkey Contractor",
                  category:"Trunkey",
                  imageUr1: trunkeyOne,
                  width: 4,
                  height: 3,
                },
                {
                  id:2,
                  title: "Trunkey Contractor",
                  category:"Trunkey",
                  imageUr1:trunkeyTwo,
                  width: 1,
            height: 1,
                },
      
                {
                  id:3,
                  title: "Trunkey Contractor",
                  category:"Trunkey",
                  imageUr1:trunkeyThree,
                  width: 3,
                  height: 4,
                },
                {
                  id:4,
                  title: "Trunkey Contractor",
                  category:"Trunkey",
                  imageUr1:trunkeyFour,
                  width: 1,
                  height: 2,
                  
                },
      
                // **************************************************Civil*******************************************************
                {
                  id:1,
                  title: "Civil",
                    category:"Civil",
                  imageUr1:civil1,
                  width: 3,
                  height: 4,
                  
                },
                {
                  id:2,
                  title: "Civil",
                  category:"Civil",
                  imageUr1:civil2,
                  width: 4,
                  height: 3,
                
                },
                {
                  id:3,
                  title: "Civil",
                  category:"Civil",
                  imageUr1:civil3,
                  width: 2,
                  height: 2,
                },
                {
                  id:4,
                  title: "Civil",
                  category:"Civil",
                  imageUr1:civil4,
                  width: 4,
                  height: 4,
                },
              
      
                // ***************************************************Modular Kitchen*************************************************************************
                {
                  id:1,
                  title: "Modular Kitchen",
                  category:"Modular",
                  imageUr1:modular1,
                  width: 1,
                  height: 1,
                },
          
                {
                  id:2,
                  title: "Modular Kitchen",
                  category:"Modular",
                  imageUr1:modular2,
                  width: 3,
                  height: 4,
                },
          
                {
                  id:3,
                  title: "Modular Kitchen",
                  category:"Modular",
                  imageUr1:modular3,
                  width: 3,
                  height: 2,
                  
                },
                {
                  id:4,
                  title: "Modular Kitchen",
                  category:"Modular",
                  imageUr1:modular4,
                  width: 3,
                  height: 4,
                  
                },
                // ***************************************************Furniture*************************************************************************
                {
                  id:1,
                  title:"Furniture",
                  category:"furniture",
                  imageUr1:furniture1,
                  width: 1,
                  height: 1,
                },
          
                {
                  id:2,
                  title: "Furniture",
                  category:"furniture",
                  imageUr1:furniture2,
                  width: 3,
                  height: 4,
                },
          
                {
                  id:3,
                  title: "Furniture",
                  category:"furniture",
                  imageUr1:furniture3,
                  width: 3,
                  height: 2,
                  
                },

                {
                  id:4,
                  title: "Furniture",
                  category:"furniture",
                  imageUr1:furniture4,
                  width: 3,
                  height: 4,
                  
                }
          
      // ]
]

export default WorkTab