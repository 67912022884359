import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import ServiceData from '../JsonData/Service';
import "./servicep.css";
import { Image } from 'antd';
import MetaTags from 'react-meta-tags';

function Servicep() {
    const search = useLocation();
    const path = search.pathname;
    const [serviceDetali, setServiceData] = useState(ServiceData);
    const EventsF = serviceDetali.find((e) => e.slug == path);
    const [items, setItems] = useState(ServiceData);
console.log("EventsF",EventsF)
    const filterItems = (category) => {
        if (category === 'All') {
            setItems(ServiceData);
        } else {
            const updatedItems = ServiceData.filter((item) => item.category === category);
            setItems(updatedItems);
        }
    };

    return (
        <>
        <MetaTags>
      <title>{EventsF.Tittle}</title>
        <meta title={EventsF.Tittle} />
        <meta
          name="description"
          content={EventsF.discription}
        />
        <meta
          name="keywords"
          content="Top Interior Designers in Mira Road,Best Interior Designer in Mira Road,Interior Service in Mira Road,Interior Designer in Mira Road,Trunkey Contractor in Mira Road,Civil Contractor in Mira Road,Modular Kitchen Designer in Mira Road,Furniture  Designer in Mira Road,Interior work,Interior Designer near Mira Road"/>
        <link rel="canonical" href="https://interiorconcepts.org/ Best Farmhouse in Wada" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content={EventsF.Tittle} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://interiorconcepts.org/Farmhouse in Wada"/>
        <meta property="og:description" content={EventsF.discription} />
        <meta property="og:image" content="https://interiorconcepts.org/static/media/omkar-nature.87735681decd4a0e08d7.jpeg" />       
      </MetaTags>
            <section className="service-banner" style={{backgroundImage:`url(${EventsF.bannerImage} )`, height:"50vh", backgroundRepeat: 'no-repeat', width:"100%",backgroundSize:"cover",backgroundPosition:"center"}}>
                <div className='container'>
                    <h1 className="design_hero_title_revamp__1GtUQ">
                        <span>
                            <span className="modular-kit">| </span> {EventsF.heading}
                        </span>
                    </h1>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="para-mod">
                        <p>{EventsF.details}</p>
                        <p className='paag'>{EventsF.details2}</p>
                    </div>
                    <div className="images-inter">
                        <div className="row">
                            {EventsF.image.map(img => (
                                <div key={img.id} className="col-lg-3 col-md-12 col-sm-12">
                                    <div className="inter-imggg">
                                        <Image src={img.src} alt="" />
                                        <h6 className='dis-6'></h6>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Servicep;
