import './Footer.css'
import logo from "../../Assets/15070888809.png"
import { FaFacebookF } from "react-icons/fa";
import { CiTwitter } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa";
import { CiYoutube } from "react-icons/ci";
import { Link } from 'react-router-dom';

function Footer() {
  
  return (
    <>
     <div className="footer-color">
     <footer className="section  footer-color">
  <div className="footer-top section-padding">
    <div className="container">
      <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n8">
        <div className="col mb-8">
          <div className="footer-widget footer-about">
            <div className="footer-logo">
              <Link to="/">
                <img src={logo}alt="logo" />
              </Link>
            </div>
            <p>
            Welcome to Interior Concept! Your path to becoming the best interior designer on Mira Road starts here. We help you create spaces that reflect your unique style and enhance your quality of life.
            </p>
            {/*  */}
          </div>
        </div>
        <div className="col mb-8">
          <div className="footer-widget footer-list">
            <h3 className="footer-title">Useful Link</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              
              <li>
                <Link to="/interior-designer-mira-roaLink">Service</Link>
              </li>
              <li>
                <Link to="/interior-work">Work</Link>
              </li>
              <li>
                <Link to="/interior-designer-near-mira-road">Contact Us </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col mb-8">
          <div className="footer-widget footer-list">
            <h3 className="footer-title">
              
              Contact Us</h3>
            <ul>
              <li>
                <span className="titl">T:</span>
                <span className="desc">093727 70662</span>
              </li>
              <li>
                <span className="titl">E:</span>
                <span className="desc">
                ajay.interiorconcepts@gmail.com <br />
                  
                </span>
              </li>
              <li>
                <span className="titl">A:</span>
                <span className="desc">
                Shop no -15, Celeste, JP infra, near, Vinay Nagar Rd, Kashimira, Mira Road East, Thane, Mira Bhayandar, Maharashtra 401107
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="col mb-8">
           <div className="footer-widget footer-newsletter">
            <h3 className="footer-title">Social Media</h3>
            <div className="social-icon-style">
            <Link className="facebook" to="https://www.facebook.com/interiorconcepts05/">
                <i className="fa fa-facebook"><FaFacebookF /></i>
              </Link>
              <Link className="google-plus" to="https://www.instagram.com/interior_concepts_/">
                <i className="fa fa-google-plus"><FaInstagram /></i>
              </Link>
            </div>
            
            {/* <div id="mc_embed_signup" className="subscribe-form">
              <form
                // id="mc-embedded-subscribe-form"
                className="validate"
                noValidate=""
                target="_blank"
                name="mc-embedded-subscribe-form"
                method="post"
                action="https://devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&id=05d85f18ef"
              >
                <div id="mc_embed_signup_scroll" className="mc-form">
                  <input
                    className="email"
                    type="email"
                    required=""
                    placeholder="Enter your email here.."
                    name="EMAIL"
                    defaultValue=""
                  />
                  <div className="mc-news" aria-hidden="true">
                    <input
                      type="text"
                      defaultValue=""
                      tabIndex={-1}
                      name="b_6bbb9b6f5827bd842d9640c82_05d85f18ef"
                    />
                  </div>
                  <div className="clear">
                    <input
                      id="mc-embedded-subscribe"
                      className="button-clear"
                      type="submit"
                      name="subscribe"
                      defaultValue="Subscribe"
                    />
                  </div>
                </div>
              </form>
            </div> */}
          </div> 
          {/* <div className="footer-widget footer-newsletter">
            <h3 className="footer-title">Newsletter</h3>
            <p>Get E-mail updates about our latest newa and special offers.</p>
            <div id="mc_embed_signup" className="subscribe-form">
              <form
                // id="mc-embedded-subscribe-form"
                className="validate"
                noValidate=""
                target="_blank"
                name="mc-embedded-subscribe-form"
                method="post"
                action="https://devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&id=05d85f18ef"
              >
                <div id="mc_embed_signup_scroll" className="mc-form">
                  <input
                    className="email"
                    type="email"
                    required=""
                    placeholder="Enter your email here.."
                    name="EMAIL"
                    defaultValue=""
                  />
                  <div className="mc-news" aria-hidden="true">
                    <input
                      type="text"
                      defaultValue=""
                      tabIndex={-1}
                      name="b_6bbb9b6f5827bd842d9640c82_05d85f18ef"
                    />
                  </div>
                  <div className="clear">
                    <input
                      id="mc-embedded-subscribe"
                      className="button-clear"
                      type="submit"
                      name="subscribe"
                      defaultValue="Subscribe"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  <div className="footer-bottom">
    <div className="container">
      <div className="copyright text-center">
        <p>
          Copyright ©2024 All rights reserved | Interior concepts{" "}
          <i className="fa fa-heart" /> by{" "}
          <Link to="https://skdm.in/contact-us-digital-marketing"> shree krishns digital marketing </Link>
          .
        </p>
      </div>
    </div>
  </div>
</footer>
     </div>
      

    </>
  )
}

export default Footer
