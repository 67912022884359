import "./nav.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import interlogo from "../../Assets/interiror-bg-rem-removebg-preview.png";
import { Link } from "react-router-dom";
import ServiceData from "../JsonData/Service";
import Button from "react-bootstrap/Button";

function Navi() {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="Navbarrr">
        <Container>
          <Navbar.Brand href="/">
            <Link to="/">
              <div className="Logo-Con">
                <img src={interlogo} alt="" />
              </div>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav
              className="my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Nav.Link href="/">
                <Link to="/">Home</Link>
              </Nav.Link>
              <Nav.Link href="best-interior-designer-mira-road">
                <Link to="/best-interior-designer-mira-road">About Us</Link>
              </Nav.Link>
              <NavDropdown title="SERVICES" id="basic-nav-dropdown">
                
                {ServiceData.map((service, index) => (
                  <NavDropdown.Item key={index}>
                    <Link to={service.slug}>
                    {service.heading}
                    </Link>
                  </NavDropdown.Item>
                ))}
                <NavDropdown.Divider />
              </NavDropdown>
              <Nav.Link href="/interior-work">
                <Link to="/interior-work">Work</Link>
              </Nav.Link>

              <Nav.Link href="/interior-designer-near-mira-road">
                <Link to="/interior-designer-near-mira-road">Contact us</Link>
              </Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link>
                <Link to="/interior-designer-near-mira-road">
                  <Button className="btn-enq" variant="dark">
                    Enquiry now
                  </Button>
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Navi;
