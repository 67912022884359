import React from 'react'
import "./raqq.css"
function CardFaq() {
  return (
    <>
     <section className='bgc-color'>
      <div className="container">
      <div className='django'>
  <div className="wrapper-class">
    <div className="wrapper-class">
      <div className=" what-we title-offer justify-space-bettween items-center mb-6">
        <div className=' djchango'>
          <h2 className="whatt-we">What we <span className='span-color'>offer</span></h2> {/**/}
          <div className="us-pp">
      <p>Custom interiors. Creative solutions. Stunning transformations.</p>
    </div>
        </div>{" "}
        
        {/* <div className="cta-block">
          <div className="">
            <div className="btn48-primary-normal">Get Free Quote</div>
          </div>
        </div> */}
      </div>{" "}
      {/**/}{" "}
      <div>
        <div className="relative">
          <div className="relative  show-next">
            <div
              instancename={1718176406116}>
              <div
                className=" gj-bro">
               <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12">
                <div
                 
                  style={{ width: "285.333px", marginRight: 20 }}
                >
                  <div className="box-shado h-ful gJg">
                    <div className="flexx justify-between items-center p-3">
                      <div className="flexx justify-between items-center">
                        <div className="relative">
                        </div>{" "}
                        <p className="ml-3 subtitle_larg">
                          Technology &amp; science
                          {/**/}
                        </p>
                      </div>{" "}
                      {/**/}
                    </div>{" "}
                    <ul className="list-disc mt-3 mb-5 ml-10 mr-3">
                      <li className="mb-4">
                        <p className="body_smal">
                          AquaBloc® Technology - Hermetically sealed edges that
                          ensure no moisture enters the panels of your modular
                          cabinets.
                          {/**/}
                        </p>
                      </li>
                      <li className="mb-4">
                        <p className="body_smal">
                          AntiBubble® Technology - Super seamless panels without
                          air bubbles for your modular cabinets.
                          {/**/}
                        </p>
                      </li>
                      <li className="mb-4">
                        <p className="body_smal">
                          DuraBuild™ Technology - Robust structure for modular
                          cabinets, making them strong and long-lasting.
                          {/**/}
                        </p>
                      </li>
                      <li className="mb-4">
                        <p className="body_smal">
                          Design Science - Modular kitchens with improved
                          accessibility that makes daily tasks more efficient
                          and reduces stress on the body.
                          {/**/}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                <div
                  style={{ width: "285.333px", marginRight: 20 }}
                >
                  <div className="box-shado h-full gJg">
                    <div className="flexx justify-between items-center p-3">
                      <div className="flexx justify-between items-center">
                        <div className="relative">
                        </div>{" "}
                        <p className="ml-3 subtitle_larg">
                          Warranty
                          {/**/}
                        </p>
                      </div>{" "}
                      {/**/}
                    </div>{" "}
                    <ul className="list-disc mt-3 mb-5 ml-10 mr-3">
                      <li className="mb-4">
                        <p className="body_smal">
                          1 Years free service (Capentry/Plumbing)
                          {/**/}
                        </p>
                      </li>
                      <li className="mb-4">
                        <p className="body_smal">
                          10 Years wood warranty
                          {/**/}
                        </p>
                      </li>
                      <li className="mb-4">
                        <p className="body_smal">
                          2 Years  Heardwear Warranty
                          {/**/}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                </div>
                <div className="col-lg-4 col-md-3 col-sm-12">
                <div className="box-shado h-ful gJg w-789 ">
                    <div className="flexx justify-between items-center p-3 col-lg-4 col-md-12 col-sm-12" >
                      <div className="flexx justify-between items-center">
                        <div className="relative">
                        </div>{" "}
                        <p className="ml-3 subtitle_larg">
                          Our services
                          {/**/}
                        </p>
                      </div>{" "}
                      {/**/}
                    </div>{" "}
                    <ul className="list-disc mt-3 mb-5 ml-10 mr-3">
                      <li className="mb-4">
                        <p className="body_smal">
                          Modular kitchens
                          {/**/}
                        </p>
                      </li>
                      <li className="mb-4">
                        <p className="body_smal">
                          Modular wardrobes
                          {/**/}
                        </p>
                      </li>
                      <li className="mb-4">
                        <p className="body_smal">
                          Lighting
                          {/**/}
                        </p>
                      </li>
                      <li className="mb-4">
                        <p className="body_smal">
                          Flooring
                          {/**/}
                        </p>
                      </li>
                      <li className="mb-4">
                        <p className="body_smal">
                          Electrical work
                          {/**/}
                        </p>
                      </li>
                      <li className="mb-4">
                        <p className="body_smal">
                          Civil work
                          {/**/}
                        </p>
                      </li>
                      <li className="mb-4">
                        <p className="body_smal">
                          False ceiling
                          {/**/}
                        </p>
                      </li>
                      <li className="mb-4">
                        <p className="body_smal">
                          Wall design &amp; painting
                          {/**/}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
               </div>
                
                
                
              </div>{" "}
              {/**/}
             
            </div>
          </div>{" "}
         
        
        </div>
      </div>{" "}
      {/**/}
    </div>
  </div>
</div>
      </div>
     </section>

    </>
  )
}

export default CardFaq