
import Interior1 from "../../Assets/interior/NAIK'S INTERIOR  PPT (1)-18.png"
import Interior2 from "../../Assets/interior/F4.jpg";
import Interior3 from "../../Assets/interior/CAM 3.jpg";
import Interior4 from "../../Assets/interior/inter.png";
import Interior5 from "../../Assets/interior/NAIK'S INTERIOR  PPT (1)-06.png";
import Interior6 from "../../Assets/interior/inter5.png";
import Interbanner from "../../Assets/interior/interior-banner.webp";
// ***************************truney********************************

import trunkey1 from "../../Assets/Trunkey/trunkey121.png"
import trunkey2 from "../../Assets/Trunkey/trunkey1.png"
import trunkey3 from "../../Assets/Trunkey/trunkey7.png"
import trunkey4 from "../../Assets/Trunkey/trunkey4.png"
import trunkey5 from "../../Assets/Trunkey/NAIK'S INTERIOR  PPT (1)-10.png"
import trunkey6 from "../../Assets/Trunkey/trunkey4.png"
// ******************************civil******************************
import civil1 from "../../Assets/Civil/civil6.png"
import civil2 from "../../Assets/Civil/civil5.png"
import civil3 from "../../Assets/Civil/civil4.png"
import civil4 from "../../Assets/Civil/civil3.png"
import civil5 from "../../Assets/Civil/civil2.png"
import civil6 from "../../Assets/Civil/civil1.png"
import civinBanner from "../../Assets/Civil/civil-banner.jpg"

// ********************Kitchen**************************************
import Kit1 from "../../Assets/Kitchen/kitchen1.png"
import Kit2 from "../../Assets/Kitchen/kitchen2.png"
import Kit3 from "../../Assets/Kitchen/kitchen3.png"
import Kit4 from "../../Assets/Kitchen/kitchen4.png"
import Kit5 from "../../Assets/Kitchen/kitchen5.png"
import Kit6 from "../../Assets/Kitchen/kitchen6.png"
import Kitchenbanner from "../../Assets/Kitchen/kitchen-banner.jpg"

// *********************Furniture***********************************
import furniture1 from "../../Assets/Furniture/fur1.png"
import furniture2 from "../../Assets/Furniture/fur2.png"
import furniture3 from "../../Assets/Furniture/fur3.png"
import furniture4 from "../../Assets/Furniture/fur4.png"
import furniture5 from "../../Assets/Furniture/fur5.png"
import furniture6 from "../../Assets/Furniture/fur6.png"
import furniturebanner from "../../Assets/Furniture/furniture-banner.png"
const ServiceData = [
    {
        id: 1,
        slug: "/interior-designer-mira-road",
        heading: "Interior designing",
        interDetail:"Kids Room",
        details: "When it comes to enhancing the aesthetics and functionality of your living space, an Interior Designer in Mira Road can make a substantial difference. Mira Road, a bustling suburb of Mumbai, is home to many seeking to transform their homes and offices into stylish, comfortable, and efficient environments.",
        details2:"An Interior Designer in Mira Road brings a wealth of expertise and creativity to the table. They understand the latest design trends, materials, and technologies, ensuring that each project is not only visually appealing but also practical. Whether you’re looking to revamp a single room or undertake a complete home renovation, these professionals have the skills to bring your vision to life.One of the significant advantages of hiring an Interior Designer in Mira Road is their deep understanding of local preferences and architectural nuances. They are well-versed in blending contemporary styles with traditional elements, creating spaces that reflect the unique character and lifestyle of Mira Road residents. From selecting the perfect colour palette to choosing the right furniture and accessories, they pay meticulous attention to detail, ensuring a harmonious and cohesive look.Moreover, working with an Interior Designer in Mira Road means you benefit from their extensive network of suppliers and contractors. This can lead to cost savings and ensure that the project is completed within the stipulated time frame. These designers often have access to exclusive resources and can source high-quality materials at competitive prices, adding value to your investment.In conclusion, a professional interior designer is your go-to expert for creating beautiful, functional spaces tailored to your needs and preferences. Their expertise, local knowledge, and industry connections make them invaluable partners in any interior design project, helping you transform your space into a true reflection of your personal style and taste. Whether you’re redesigning your home or office, these professionals can turn your dreams into reality.",
        Tittle:"Interior Designer in Mira Road | Interior Concepts",
        discription:"Discover the premier Interior Designer in Mira Road to transform your space with style. Elevate your home or office with our expertise!",
        bannerImage: Interbanner,
        image: [
            { id: 1, src: Interior1 },
            { id: 2, src: Interior2 },
            { id: 3, src: Interior3 },
            { id: 4, src: Interior4 },
            { id: 5, src: Interior5 },
            { id: 6, src: Interior6 }
        ]
    },
    {
        id: 2,
        slug: "/turnkey-contractor-mira-road",
        heading: "Turnkey interior",
        details: "When it comes to transforming a house into a dream home, finding the right Turnkey Contractor in Mira Road can make all the difference. Turnkey Interior Solutions offers a comprehensive service that handles every aspect of interior design and execution, ensuring a seamless experience for homeowners. In Mira Road, the demand for turnkey contractors is on the rise due to the area's rapid development and influx of new residential projects.",
        bannerImage: trunkey3,
        details2:"A reliable Turnkey Contractor in Mira Road takes the hassle out of the renovation process by managing everything from conceptualization to completion. This includes initial design consultations, material selection, procurement, construction, and final finishing touches. By opting for a turnkey solution, homeowners can avoid the stress of coordinating with multiple vendors and contractors. Instead, they have a single point of contact who oversees the entire project, ensuring that timelines and budgets are adhered to.Choosing the right Turnkey contractor in Mira Road involves considering several factors. Experience and expertise are crucial, as seasoned contractors bring valuable insights and innovative solutions to the table. Reviewing their portfolio can provide a glimpse into their design aesthetics and quality of work. Additionally, client testimonials and references offer an understanding of their reliability and customer satisfaction levels.Turnkey contractors in Mira Road are well-versed in the latest trends and technologies in interior design. They leverage this knowledge to create functional and aesthetically pleasing spaces that reflect the homeowner's personality and lifestyle. Whether it's a modern apartment or a traditional house, these contractors can tailor their services to meet specific needs and preferences.In conclusion, hiring a Turnkey Contractor in Mira Road is an excellent investment for those looking to achieve a hassle-free and professionally managed interior renovation. With their all-encompassing services, homeowners can look forward to a beautifully designed space without the usual headaches associated with home improvement projects.",
        Tittle:"Trunkey Contractor in Mira Road | Interior Concepts",
        discription: "Trust our team as the trusted Turnkey Contractor in Mira Road for complete construction solutions. From planning to execution, we handle every aspect.",
        image: [
            { id: 1, src: trunkey1 },
            { id: 2, src: trunkey2 },
            { id: 3, src: trunkey3 },
            { id: 4, src: trunkey4 },
            { id: 5, src: trunkey5 },
            { id: 6, src: trunkey6 }
        ]
    },
    {
        id: 3,
        slug: "/civil-contractor-mira-road",
        heading: "Civil",
        details: "If you're planning a construction project, finding a reliable and experienced civil contractor is crucial to ensure the job is done right. For those residing in Mira Road, the search for a competent civil contractor ends here. The bustling suburb of Mira Road has seen a significant increase in development over the past few years, making the role of a skilled civil contractor in Mira Road more important than ever.",
        details2:"A civil contractor in Mira Road can help manage various aspects of your construction project, from planning and design to execution and completion. They bring expertise in handling both residential and commercial projects, ensuring that every detail meets the required standards and regulations. Whether it's constructing a new building, renovating an existing structure, or managing infrastructure projects, a civil contractor in Mira Road has the knowledge and resources to deliver high-quality results.One of the key advantages of hiring a civil contractor in Mira Road is their familiarity with local regulations and building codes. This local expertise ensures that your project adheres to all legal requirements, avoiding potential delays or fines. Additionally, these contractors often have established relationships with local suppliers and subcontractors, which can lead to cost savings and more efficient project timelines.Moreover, a reputable civil contractor in Mira Road prioritizes safety and sustainability. They implement best practices to ensure the safety of workers and the surrounding community throughout the construction process. Furthermore, they are increasingly adopting sustainable building practices, utilizing eco-friendly materials and technologies to minimize the environmental impact of their projects.In conclusion, the importance of selecting a competent civil contractor in Mira Road cannot be overstated. Their expertise, local knowledge, and commitment to quality and safety make them an invaluable partner in any construction project. By choosing a trusted contractor, you can ensure that your project is completed on time, within budget, and to the highest standards of excellence.",
        Tittle:"Civil Contractor in Mira Road | Interior Concepts",
        discription: "Trust our team as the trusted Civil Contractor in Mira Road for reliable construction services. From residential to commercial projects, we ensure quality.",
        bannerImage: civinBanner,
        image: [
            { id: 1, src: civil1 },
            { id: 2, src: civil2 },
            { id: 3, src: civil3 },
            { id: 4, src: civil4 },
            { id: 5, src: civil5 },
            { id: 6, src: civil6 }
        ]
    },
    {
        id: 4,
        slug: "/modular-kitchen-designer-mira-road",
        heading: "Modular Kitchen",
        details: "A modern home is incomplete without a stylish and functional kitchen, and this is where the expertise of a Modular Kitchen Designer in Mira Road comes into play. The concept of modular kitchens has revolutionised the way we think about kitchen design, emphasising efficiency, aesthetics, and customization.",
        details2:"Modular kitchens are composed of pre-made cabinet parts that are easily assembled to fit the specific dimensions of your kitchen space. This not only ensures a perfect fit but also offers the flexibility to rearrange components or add new ones over time. For residents in Mira Road, a skilled Modular Kitchen Designer in Mira Road can transform your kitchen into a space that is both practical and visually appealing.One of the significant advantages of hiring a Modular Kitchen Designer in Mira Road is their local expertise. They understand the unique needs and preferences of homeowners in the area, ensuring that every design is tailored to meet specific requirements. Whether you prefer a contemporary look with sleek lines and minimalist finishes or a more traditional style with warm tones and classic cabinetry, these designers can bring your vision to life.In cities like Mira Road, where space can be a premium, a well-designed modular kitchen maximises every inch, providing ample storage while maintaining an uncluttered appearance. Features like pull-out shelves, corner units, and integrated appliances help optimize space and improve accessibility.In addition to functionality, the aesthetics of a modular kitchen play a crucial role. A professional designer can suggest the best materials, colours, and finishes that complement the rest of your home’s decor. The right combination can create a harmonious flow throughout your living space, making your kitchen a true centrepiece.Investing in a modular kitchen not only enhances the value of your home but also elevates your daily cooking experience. With the guidance of a skilled Modular Kitchen Designer in Mira Road, you can achieve a kitchen that is both beautiful and highly functional, tailored perfectly to your lifestyle needs.",
        Tittle:" Modular Kitchen Designer in Mira Road | Interior Concepts",
        discription: "Discover the top Modular Kitchen Designer in Mira Road. Get stylish and functional kitchen designs tailored to your space and needs.",
        bannerImage: Kitchenbanner,
        image: [
            { id: 1, src: Kit1 },
            { id: 2, src: Kit2 },
            { id: 3, src: Kit3 },
            { id: 3, src: Kit4 },
            { id: 3, src: Kit5 },
            { id: 3, src: Kit6 },
            
        ]
    },
    {
        id: 5,
        slug: "/furniture-designer-mira-road",
        heading: "Furniture",
        category: "furniture",
        details: "When it comes to creating a perfect living space, a Furniture Designer in Mira Road can transform your vision into reality. Mira Road, known for its vibrant community and growing urban landscape, boasts a range of talented furniture designers who cater to diverse tastes and styles. Whether you’re looking to furnish a new home or refurbish an old one, engaging a Furniture Designer in Mira Road can significantly enhance the aesthetic and functional appeal of your interiors.",
        details2:"A Furniture Designer in Mira Road brings a unique blend of creativity and technical expertise. They understand the importance of customizing pieces that reflect your personality while ensuring practicality. From sleek, modern designs to classic, timeless pieces, these designers have the skills to create furniture that fits perfectly within your space. Their deep knowledge of materials, finishes, and construction techniques allows them to craft durable and stylish furniture that can withstand the test of time.Choosing a local Furniture Designer in Mira Road also means benefiting from personalised service. These designers often work closely with clients, offering bespoke solutions that large furniture chains simply cannot match. They take the time to understand your needs, preferences, and budget, ensuring that every piece they create aligns with your vision. Additionally, local designers are more accessible for consultations and follow-ups, making the entire process smoother and more collaborative.Moreover, supporting a Furniture Designer on Mira Road contributes to the local economy. By choosing locally crafted furniture, you’re not only getting unique and high-quality pieces but also supporting local artisans and businesses. This fosters a sense of community and helps sustain the region’s creative industry.In summary, a Furniture Designer in Mira Road offers a personalised, high-quality, and community-focused approach to furnishing your home. Their expertise and commitment to craftsmanship can help you create a living space that is both beautiful and functional.",
        Tittle:"Furniture  Designer in Mira Road | Interior Concepts",
        discription: "Find the best Furniture Designer in Mira Road. Get custom furniture designs tailored to your style and needs. Explore our services now!",
        bannerImage: furniturebanner,
        image: [
            { id: 1, src: furniture1 },
            { id: 2, src: furniture2 },
            { id: 3, src: furniture3 },
            { id: 4, src: furniture4 },
            { id: 5, src: furniture5 },
            { id: 6, src: furniture6 },
        ]
    }
];
export default ServiceData