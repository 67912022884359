import React from 'react'
import Aboutp from '../Components/AboutP/Aboutp'
import Testimonial from '../Components/Testiminiall/Testimonial'
import Footer from '../Components/Footer/Footer'

function AboutPage() {
  return (
    <>
      <Aboutp/>
      <Testimonial/>
      <Footer/>
    </>
  )
}

export default AboutPage
